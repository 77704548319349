import React, {ReactNode} from "react";
import {css, Theme, useTheme} from "@emotion/react";

import {Button} from "@pg-design/button";
import {elevation} from "@pg-design/elevation";
import {borderRadius, calculateRemSize, flex, flexAbsoluteCenter, mb, onDesktop, p, ph, pt} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text";

interface IProps {
    title: string;
    iconSlot: ReactNode;
    description: string;
    actionBtnText?: string;
    actionDescription: string;
    onActionClick?: () => void;
    customButton?: ReactNode;
}

export const PromoBoxHorizontal = (props: IProps) => {
    const theme = useTheme();
    return (
        <div css={wrapperStyle}>
            <div css={infoColumnStyle}>
                <div css={iconWrapperStyle}>{props.iconSlot}</div>
                <div css={textWrapperStyle}>
                    <Text variant="headline_6" mb="0.4rem">
                        {props.title}
                    </Text>
                    <Text variant="body_copy_2">{props.description}</Text>
                </div>
            </div>
            <div css={actionsColumnStyle}>
                {props.customButton || (
                    <Button variant="filled_primary" onClick={props.onActionClick} css={buttonStyle}>
                        {props.actionBtnText}
                    </Button>
                )}
                <Text variant="info_txt_1" color={theme.colors.gray[700]} align="center">
                    {props.actionDescription}
                </Text>
            </div>
        </div>
    );
};

const wrapperStyle = css`
    ${p(3)};
    ${flex("center", "space-between")};
    flex-direction: column;
    width: 100%;
    background: #fff;
    ${elevation(0)};
    ${borderRadius(1)};

    ${onDesktop(css`
        flex-direction: row;
    `)};
`;

export const infoColumnStyle = css`
    ${flex("center", "flex-start")};
    flex-direction: row;
    width: 100%;
    gap: ${calculateRemSize(2)};
`;

const actionsColumnStyle = css`
    ${pt(4)};
    ${flex("center", "center")};
    flex-direction: column;
    width: 100%;

    ${onDesktop(css`
        width: auto;
        ${pt(0)};
    `)};
`;

export const iconWrapperStyle = (theme: Theme) => css`
    ${flexAbsoluteCenter};
    flex: 0 0 auto;
    width: ${calculateRemSize(7)};
    height: ${calculateRemSize(7)};
    ${borderRadius(4)};
    background-color: ${theme.colors.background[200]};
`;

export const textWrapperStyle = css`
    ${onDesktop(css`
        max-width: 32rem;
    `)};
`;

export const buttonStyle = css`
    ${mb(1)};
    ${ph(4)};
    width: 100%;

    ${onDesktop(css`
        width: auto;
    `)};
`;
