import React from "react";
import {connect} from "react-redux";
import {css, Theme} from "@emotion/react";
import styled from "@emotion/styled";
import * as lscache from "lscache";
import {bindActionCreators, Dispatch} from "redux";

import {Button} from "@pg-design/button";
import {mb, p} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text-module";
import {pick} from "@pg-mono/nodash";

import {APPLICATION_LOCAL_STORAGE_KEY} from "../../app/constants/storage_keys";
import {IRPStore} from "../../app/rp_reducer";
import {BoundAction} from "../../store/utils/bound_action";
import {hitNotificationConsentSuccess, hitNotificationConsentView} from "../../tracking/algolytics_hits/application_hit";
import {gtmEventPostApplicationModal} from "../../tracking/google_tag_manager/gtm_event_modal";
import {ViewType} from "../../view_type/ViewType";
import {applicationPatch} from "../actions/application_form_actions";
import {IApplicationData} from "../reducers/application_reducer";
import {IApplication} from "../types/IApplication";
import {ApplicationSourceSection} from "../utils/ApplicationSource";

interface IStateProps {
    data: IApplicationData | undefined;
    viewType: ViewType | null;
}

interface IActionsProps {
    applicationPatch: BoundAction<typeof applicationPatch>;
}
interface IOwnProps {
    storeHash: string;
    result: IApplication | null;
    onSuccessfulSubmit?: (sentApplication: IApplication | null) => void;
    onModalClose: () => void;
}
interface IProps extends IStateProps, IActionsProps, IOwnProps {
    sourceSection: ApplicationSourceSection;
}

class NotificationConsentConfirmationC extends React.Component<IProps, {}> {
    public componentDidMount() {
        const data = this.props.data;
        if (data == null) {
            throw new Error("NotusForm componentDidMount: application data should be filled already");
        }

        if (this.props.result) {
            // gtm
            gtmEventPostApplicationModal("zgoda marketingowa", "wyswietlenie");
            // algolytics
            hitNotificationConsentView(this.props.result, this.props.sourceSection, data, {extraPayloadViewType: this.props.viewType ?? undefined});
        }
    }

    private onSubmit = () => {
        const data = this.props.data;
        if (data == null) {
            throw new Error("NotusForm onSubmit: application data should be filled already");
        }

        const {storeHash, sourceSection} = this.props;
        const newApplication = {...this.props.result, new_property_notification_consent: true} as IApplication | null;
        if (!newApplication || !newApplication.uuid) {
            throw new Error("OfferSubscribe submit: no uuid in result");
        }

        const formInfoToBeSaved = pick(newApplication, ["email", "name", "phone", "financing_services", "new_property_notification_consent"]);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (this.props.applicationPatch(newApplication.uuid, {new_property_notification_consent: true}, storeHash) as any).then(
            (res: {application: IApplication}) => {
                if (!res || !res.application) {
                    return;
                }
                // update LS and trigger callback
                lscache.set(APPLICATION_LOCAL_STORAGE_KEY, formInfoToBeSaved);
                this.props.onSuccessfulSubmit && this.props.onSuccessfulSubmit(newApplication);
                // gtm
                gtmEventPostApplicationModal("zgoda marketingowa", "klikniecie");
                // algolytics
                hitNotificationConsentSuccess({...this.props.result, ...res.application}, sourceSection, data, {
                    extraPayloadViewType: this.props.viewType ?? undefined
                });
            }
        );
        this.props.onModalClose();
    };

    public render() {
        return (
            <div css={bodyHolder}>
                <Text variant="headline_3" as="h1" css={headingStyle}>
                    Bądź na bieżąco z najnowszymi inwestycjami i promocjami deweloperskimi!
                </Text>

                <Text variant="body_copy_1" css={subheadingStyle}>
                    Kliknij poniższy link, a będziesz pewien, że żadna okazja Cię nie ominie - dowiesz się o niej od razu mailem lub SMSem.
                </Text>

                <ConsentConfirmationButton onClick={this.onSubmit} variant="filled_primary" css={buttonStyle}>
                    Chcę otrzymywać linki do najnowszych ofert
                </ConsentConfirmationButton>

                <GrayText variant="info_txt_1" align="center">
                    * możesz dowolnie zmieniać ustawienia tych powiadomień na swoim koncie.
                </GrayText>
            </div>
        );
    }
}

export const NotificationConsentConfirmation = connect<IStateProps, IActionsProps, IOwnProps, IRPStore>(
    mapStateToProps,
    mapActionsToProps
)(NotificationConsentConfirmationC);

/**
 * Connect
 */

function mapStateToProps(state: IRPStore, props: IOwnProps): IStateProps {
    return {
        data: state.application[props.storeHash],
        viewType: state.viewType.current
    };
}
function mapActionsToProps(dispatch: Dispatch): IActionsProps {
    return bindActionCreators({applicationPatch}, dispatch);
}

const bodyHolder = (theme: Theme) => css`
    ${p(6, 2, 6, 2)};
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 0 auto;

    @media (min-width: ${theme.breakpoints.md}) {
        ${p(5)};
    }
`;

const headingStyle = (theme: Theme) => css`
    ${mb(4)};
    color: ${theme.colors.gray[800]};
    text-align: left;

    @media (min-width: ${theme.breakpoints.md}) {
        ${mb(4)};
        text-align: center;
    }
`;

const subheadingStyle = (theme: Theme) => css`
    color: ${theme.colors.gray[800]};
    margin-bottom: 8rem;
    text-align: left;

    @media (min-width: ${theme.breakpoints.md}) {
        ${mb(4)}
        text-align: center;
    }
`;

const buttonStyle = (theme: Theme) => css`
    ${mb(4)};

    @media (min-width: ${theme.breakpoints.md}) {
        ${mb(3)}
    }
`;

const ConsentConfirmationButton = styled(Button)`
    white-space: break-spaces;
`;

const GrayText = styled(Text)`
    color: ${(props) => props.theme.colors.gray[700]};
`;
