import React, {useState} from "react";
import {useSelector} from "react-redux";
import {css, Theme} from "@emotion/react";

import {FormikForm, handleFormikPost, IFormikSubmitFn} from "@pg-design/formik-utils";
import {h100, p} from "@pg-design/helpers-css";

import {IRPStore} from "../../../app/rp_reducer";
import {MultiLeadWrapper} from "../../../lead/components/MultiLeadWrapper";
import {IRecommendedProperty} from "../../../recommendations/types/IRecommendedProperty";
import {ViewType} from "../../../view_type/ViewType";
import {applicationFormValidationSchema} from "../../constants/application_form";
import {MAX_DISTANCE_FROM_ORIGINAL_OFFER_KM_ALL_REGIONS, MAX_DISTANCE_FROM_ORIGINAL_OFFER_KM_WARSAW} from "../../constants/max_distance";
import {WARSAW_REGION_ID} from "../../constants/region_ids";
import {useEmailVerification} from "../../hooks/use_email_verification";
import {useMultiLeadData} from "../../hooks/use_multi_lead_data";
import {useMultiLeadFormSend} from "../../hooks/use_multi_lead_form_send";
import {useRecommendationsSelection} from "../../hooks/use_recommendations_selection";
import {IApplicationData} from "../../reducers/application_reducer";
import {IApplicationFormValues} from "../../types/IApplicationFormValues";
import {IApplicationOffer} from "../../types/IApplicationOffer";
import {IApplicationProperty} from "../../types/IApplicationProperty";
import {IMultileadSummaryStepState} from "../../types/IMultileadSummaryStepState";
import {MultiLeadRecommendationType} from "../../types/MultiLeadRecommendationType";
import {ApplicationSourceSection} from "../../utils/ApplicationSource";
import {report} from "../../utils/recommended_application/analytics_events";
import {isRecommendationRecommendedProperty} from "../../utils/recommended_application/recommendation_type_guards";
import {
    transformRecommendedOfferIntoMultiLeadData,
    transformRecommendedPropertyIntoMultiLeadData
} from "../../utils/recommended_application/transform_recommended_into_multi_lead_data";
import {SameOfferRecommendedProperties} from "./SameOfferRecommendedProperties";

const MAX_SHOWN_CURLED = 3;

interface IProps {
    leadData: IApplicationData;
    storeHash: string;
    recommendationType: MultiLeadRecommendationType;
    sourceSection: ApplicationSourceSection;
    originalApplicationFormValues: IApplicationFormValues;
    recommendationsWithPriceFilter: boolean;
    customSourceSection?: string;
    handleModalClose: () => void;
    sameOfferRecommendedProperties: IRecommendedProperty[] | null;
    handleSameOfferRecommendedPropertiesAfterStep: (payload: IMultileadSummaryStepState, offer?: IApplicationOffer, property?: IApplicationProperty) => void;
}

export const SameOfferRecommendedPropertiesModal = (props: IProps) => {
    const {originalApplicationRegionCityID, recommendedProperties, recommendedOffers} = useMultiLeadData(props);
    const originalApplication = useSelector((store: IRPStore) => store.application[props.storeHash]);
    const multiLeadProperty = originalApplication?.property?.detail;

    const [isMultiLeadSubmitting, setIsMultiLeadSubmitting] = useState(false);

    const {validateEmailOnce} = useEmailVerification();
    const {sendMultiLeadForm} = useMultiLeadFormSend(props);

    const maxRecommendationsShownOnCurl = calcMaxRecommendations(recommendedProperties, originalApplicationRegionCityID);
    const {selectedRecommendations, toggleRecommendation} = useRecommendationsSelection({
        recommendedOffers,
        recommendedProperties,
        maxRecommendationsShown: maxRecommendationsShownOnCurl,
        recommendationType: props.recommendationType
    });

    const handleChange = (viewType: ViewType, recommendationId: number) => {
        const isCheckboxSelected = toggleRecommendation(recommendationId);
        // GTM & Algolytics events
        report.checkboxChange(props.recommendationType, viewType, isCheckboxSelected, recommendationId);
    };

    const onSubmit: IFormikSubmitFn<IApplicationFormValues> = async (formValues, formikHelpers) => {
        if (formValues?.email && !(await validateEmailOnce(formValues?.email))) {
            return;
        }

        setIsMultiLeadSubmitting(true);

        handleFormikPost(
            sendMultiLeadForm({
                selectedRecommendations,
                formValues
            }),
            formikHelpers,
            {
                onSuccess: (recommendedPropertyApplications) => {
                    if (props.recommendationType === MultiLeadRecommendationType.PROPERTY) {
                        props.handleSameOfferRecommendedPropertiesAfterStep(
                            {
                                multiLeadSummaryItems: recommendedPropertyApplications.map(([_application, appliedRecommendation]) => {
                                    if (isRecommendationRecommendedProperty(appliedRecommendation)) {
                                        return transformRecommendedPropertyIntoMultiLeadData(appliedRecommendation);
                                    }

                                    return transformRecommendedOfferIntoMultiLeadData(appliedRecommendation);
                                }),
                                multiLeadProperty
                            },
                            props.leadData?.offer?.detail,
                            props.leadData?.property?.detail
                        );
                    }

                    if (props.recommendationType === MultiLeadRecommendationType.OFFER) {
                        props.handleModalClose();
                    }
                },
                onFieldError: () => setIsMultiLeadSubmitting(false),
                onNonFieldError: () => setIsMultiLeadSubmitting(false)
            }
        );
    };

    return (
        <MultiLeadWrapper>
            <div css={holder}>
                <FormikForm
                    onSubmit={onSubmit}
                    initialValues={props.originalApplicationFormValues}
                    validationSchema={applicationFormValidationSchema}
                    css={props.recommendationsWithPriceFilter && h100}
                >
                    <SameOfferRecommendedProperties
                        isSubmitting={isMultiLeadSubmitting}
                        onClose={props.handleModalClose}
                        onSelect={handleChange}
                        recommendedProperties={recommendedProperties}
                        selected={selectedRecommendations}
                    />
                </FormikForm>
            </div>
        </MultiLeadWrapper>
    );
};

const calcMaxRecommendations = (recommendations: IRecommendedProperty[], originApplicationRegionCityID: number | undefined) => {
    const maxRecommendationDistance =
        originApplicationRegionCityID && originApplicationRegionCityID === WARSAW_REGION_ID
            ? MAX_DISTANCE_FROM_ORIGINAL_OFFER_KM_WARSAW
            : MAX_DISTANCE_FROM_ORIGINAL_OFFER_KM_ALL_REGIONS;

    const baseRecommendationsGroupAmount = recommendations.filter((recommendation) => recommendation.distance < maxRecommendationDistance).length;

    return baseRecommendationsGroupAmount > 0 && baseRecommendationsGroupAmount < MAX_SHOWN_CURLED ? baseRecommendationsGroupAmount : MAX_SHOWN_CURLED;
};

const holder = (theme: Theme) => css`
    ${p(2)};
    position: relative;
    display: flex;
    flex-direction: column;

    @media (min-width: ${theme.breakpoints.md}) {
        ${p(3)};
        height: 100%;
        max-height: calc(100vh - 6.5rem);
        overflow-y: scroll;
    }
`;
