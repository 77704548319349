import {useRef} from "react";
import {useSelector} from "react-redux";
import {css, Theme} from "@emotion/react";

import {Button} from "@pg-design/button";
import {calculateRemSize, flex, flexAlignCenter, mt, p, pb, pt, verticalFadeOut, zIndex} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text-module";
import {useScrollableListItemTracking} from "@pg-mono/hooks";
import {head} from "@pg-mono/nodash";

import {IRPStore} from "../../../app/rp_reducer";
import {CloseIconButton} from "../../../atoms/CloseIconButton";
import {FullHeightContent} from "../../../atoms/FullHeightContent";
import {notifyBugsnag} from "../../../errors/bugsnag/notify_bugsnag";
import {useScrollFadeOut} from "../../../hooks/use_scroll_fade_out";
import {RodoText} from "../../../lead/components/common/RodoText";
import {hitMultileadRecommendationShown} from "../../../lead/tracking/hit_multilead_recommendation_shown";
import {MultileadVersion} from "../../../lead/types/MultileadVersion";
import {RecommendationSource} from "../../../lead/types/RecommendationSource";
import {IRecommendedProperty} from "../../../recommendations/types/IRecommendedProperty";
import {ViewType} from "../../../view_type/ViewType";
import {MultiLeadRecommendationType} from "../../types/MultiLeadRecommendationType";
import {SameOfferRecommendedProperty} from "./SameOfferRecommendedProperty";

interface IProps {
    isSubmitting: boolean;
    onClose: () => void;
    onSelect: (viewType: ViewType, id: number) => void;
    recommendedProperties: IRecommendedProperty[];
    selected: number[];
}

export const SameOfferRecommendedProperties = (props: IProps) => {
    const onSelect = (id: number) => props.onSelect(ViewType.OFFER, id);

    const property = head(props.recommendedProperties) || null;
    const disableApplicationSubmit = !props.selected.length;

    const viewType = useSelector((store: IRPStore) => store.viewType.current);
    const {fadeOutRef, showBottomFadeOut, showTopFadeOut} = useScrollFadeOut<HTMLDivElement>();
    const recommendationListRef = useRef<HTMLDivElement>(null);
    useScrollableListItemTracking({
        scrollableListRef: recommendationListRef,
        listElementTrackingIdDatasetName: "trackId",
        handleListItemTrackingHit: (trackingId) => {
            hitMultileadRecommendationShown({
                recommendationId: trackingId,
                recommendationType: MultiLeadRecommendationType.PROPERTY,
                recommendationSource: RecommendationSource.DISTANCE,
                multileadVersion: MultileadVersion.RP_LIST_PROPERTY,
                viewType
            });
        },
        onError: (error) => {
            console.warn(error);
            notifyBugsnag(new Error(error), error);
        }
    });

    return (
        <FullHeightContent css={container}>
            <CloseIconButton css={zIndex(10)} onClick={props.onClose} right={0.5} top={0.5} />
            <FullHeightContent.Header>
                <Text align="center" as="div" css={header} variant="headline_3">
                    Zapytaj o tańsze mieszkania w tej inwestycji
                </Text>
            </FullHeightContent.Header>
            <FullHeightContent.Main css={verticalFadeOut(showTopFadeOut, showBottomFadeOut)} ref={fadeOutRef}>
                <div css={properties} ref={recommendationListRef}>
                    {props.recommendedProperties.map((property) => {
                        return (
                            <SameOfferRecommendedProperty
                                key={property.id}
                                data-track-id={property.id}
                                onSelect={onSelect}
                                property={property}
                                selected={props.selected}
                            />
                        );
                    })}
                </div>
            </FullHeightContent.Main>
            <FullHeightContent.Footer css={footer}>
                <Button disabled={disableApplicationSubmit || props.isSubmitting} variant="filled_primary">
                    Wyślij zapytanie
                </Button>

                {!!property && (
                    <RodoText
                        vendorName={property.offer.vendor.name}
                        privacyPolicyUrl={property.offer.configuration.privacy_policy_url}
                        privacyDataProcessorName={property.offer.configuration.personal_data_processor_name}
                        personalDataProcessorUrl={property.offer.configuration.personal_data_processor_url}
                        css={rodo}
                        align="center"
                    />
                )}
            </FullHeightContent.Footer>
        </FullHeightContent>
    );
};

const container = (theme: Theme) => css`
    height: calc(100vh - ${calculateRemSize(4)});
    position: relative;

    @media (min-width: ${theme.breakpoints.md}) {
        height: 100%;
    }
`;

const header = css`
    position: relative;
    flex: 0 1 auto;
    ${p(0, 3, 3)};
`;

const properties = (theme: Theme) => css`
    ${flex("center")};
    flex-direction: column;
    row-gap: ${calculateRemSize(6)};
    ${pb(6)};

    @media (min-width: ${theme.breakpoints.md}) {
        align-items: flex-start;
        ${pb(3)};
        row-gap: ${calculateRemSize(2)};
    }
`;

const footer = css`
    ${flexAlignCenter};
    flex-direction: column;
    ${pt(3)};
`;

const rodo = css`
    ${mt(2)};
    max-width: 40rem;
`;
