import {Dispatch} from "redux";

import {appendQueryString, getRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {apiV2Link} from "@pg-mono/rp-api-routes";

import {IRecommendedProperty} from "../../recommendations/types/IRecommendedProperty";

const FETCH_RECOMMENDED_PROPERTIES_PREFIX = "similar_properties/FETCH_RECOMMENDED_PROPERTIES_PREFIX";
export const fetchRecommendedPropertiesTypes = createRequestActionTypes(FETCH_RECOMMENDED_PROPERTIES_PREFIX);

interface IRecommendedPropertiesResponse {
    results: IRecommendedProperty[];
}

export const fetchRecommendedProperties =
    (propertyId: number, excludedOfferIds: number[], filters: Record<string, string | number> = {}) =>
    (dispatch: Dispatch) => {
        dispatch({type: fetchRecommendedPropertiesTypes.start});

        const url = appendQueryString(apiV2Link.property.similar({propertyId}), {
            page_size: 6,
            exclude_offer: excludedOfferIds,
            exclude_grouped: 1, // exclude offers from the same group of stages
            ...filters
        });

        return getRequest({}, url).then(
            (json: IRecommendedPropertiesResponse) => {
                dispatch({type: fetchRecommendedPropertiesTypes.success, result: json.results});
                return json.results;
            },
            () => {
                dispatch({type: fetchRecommendedPropertiesTypes.success, result: []});
                return [];
            }
        );
    };

export const resetRecommendedProperties = () => ({type: fetchRecommendedPropertiesTypes.reset});
