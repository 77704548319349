export enum LeadModalStep {
    APPLICATION_FORM,
    NOTIFICATION_CONSENT,
    SUCCESS_INFO,
    GOODBYE_INFO,
    MULTILEAD,
    // TODO: Do we actually use SINGLE_LEAD_SUMMARY anymore?
    SINGLE_LEAD_SUMMARY,
    MULTILEAD_SUMMARY,
    ARCHITECT_OFFER,
    APPLIED_ARCHITECT_OFFER,
    ARCHITECT_MULTILEAD,
    PM_OFFER,
    PM_APPLIED_OFFER,
    USER_BUDGET,
    USER_BUDGET_MULTI_LEAD,
    USER_BUDGET_NO_RECOMMENDATIONS,
    CLOSE
}
