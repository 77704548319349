import React from "react";
import {css, useTheme} from "@emotion/react";

import {onDesktop, pb} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text-module";
import {areaFormat, pluralize} from "@pg-mono/string-utils";

import {getOfferTypeNameCapital} from "../../../../../offer/helpers/OfferType";

export interface IMultiLeadCardPropertyHeaderProps {
    offerType: number;
    offerAddress: string;
    rooms?: number;
    area?: number;
}

const roomsPluralize = pluralize(["pokój", "pokoje", "pokoi"]);

export const MultiLeadCardPropertyHeader = (props: IMultiLeadCardPropertyHeaderProps) => {
    const theme = useTheme();

    const displayType = getOfferTypeNameCapital(props.offerType);

    return (
        <>
            <Text variant="headline_6" css={propertyHeaderStyles}>
                {`${displayType}, ${props.rooms ? `${props.rooms} ${roomsPluralize(props.rooms)}, ` : ""}${props.area ? areaFormat(props.area) : ""}`}
            </Text>
            <Text variant="info_txt_1" color={theme.colors.gray[800]} css={pb(1)}>
                {props.offerAddress}
            </Text>
        </>
    );
};

const propertyHeaderStyles = css`
    line-height: 3rem;

    ${onDesktop(css`
        // it is necessary to override styles on desktop (media query)
        line-height: 3rem;
    `)};
`;
