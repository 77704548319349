import React, {useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {css, Theme, useTheme} from "@emotion/react";

import {Button} from "@pg-design/button";
import {Expandable} from "@pg-design/expandable";
import {backgroundColor, borderRadius, calculateRemSize, flex, flexAbsoluteCenter, mb, mh, mt, onDesktop, p, pb, ph, w100} from "@pg-design/helpers-css";
import {BrandCheckboxCheckedIcon, BrandHandclapIcon, CheckIcon, ChevronDownIcon, ChevronUpIcon} from "@pg-design/icons-module";
import {Image} from "@pg-design/image-module";
import {Text} from "@pg-design/text-module";
import {useUserDevice} from "@pg-mono/user-device";

import {IRPStore} from "../../app/rp_reducer";
import {MultiLeadApplicationSummary} from "../../application/components/multi_lead/parts/multi_lead_application_summary/MultiLeadApplicationSummary";
import {IApplicationData} from "../../application/reducers/application_reducer";
import {AdditionalOfferCategoryGTMEventAction, multiLeadAdditionalOfferAnalytics} from "../../application/tracking/multilead_additional_offers_tracking";
import {IApplication} from "../../application/types/IApplication";
import {IApplicationFormValues} from "../../application/types/IApplicationFormValues";
import {IModalStepState} from "../../application/types/IModalStepState";
import {IMarketplaceArchitectOfferSummary} from "../../architect_application/api/fetch_marketplace_architect_promoted_offers_by_region";
import {useMarketplaceArchitectOfferForRegion} from "../../architect_application/hooks/use_marketplace_architect_offer_summary";
import {IRegionAncestor} from "../../offer/list/actions/offer_list_selected_data_actions";
import {useRegionEstimatedPrice} from "../../region/hooks/use_region_estimated_price";
import {forceFetchUserProfile} from "../../user/actions/fetch_user_profile";
import {patchUserConsent} from "../../user/api/patch_user_consent";
import {MarketingConsentType} from "../../user/ts/enums/MarketingConsentType";
import {formatApplicationCount} from "../utils/application_pluralize";
import {getLeadsSummaryIncludingMainLead} from "../utils/get_leads_summary_including_main_lead";
import {KMPromoBoxHorizontal} from "./common/KMPromoBoxHorizontal";
import {buttonStyle, iconWrapperStyle, infoColumnStyle, PromoBoxHorizontal, textWrapperStyle} from "./common/PromoBoxHorizontal";

const multiLeadOffersPromoImg = require("../../application/images/multi_lead_promo/multi_lead_offers_promo.png");
const multiLeadArchitectPackagesPromoImg = require("../../application/images/multi_lead_promo/multi_lead_architect_packages_promo.png");

enum IUserConsentChangeStatus {
    DEFAULT,
    IN_PROGRESS,
    DONE
}

type IProps = {
    storeHash: string;
    leadData: IApplicationData | null;
    originalApplicationFormValues: IApplicationFormValues;
    leadModalStepState: IModalStepState;
    handleSetNextStep: (originalApplicationResponse: IApplication | null) => void;
    handleCloseModal: () => void;
    handleSetArchitectInteriorDesignOfferStep: (architectData: IMarketplaceArchitectOfferSummary, architectRegion: IRegionAncestor | null) => void;
    handleSetPmOfferStep: () => void;
};

export const MultileadSentSummaryWithCollapsedOffers = (props: IProps) => {
    const theme = useTheme();
    const {isMobile} = useUserDevice();
    const dispatch = useDispatch();
    const {leadData, leadModalStepState, storeHash, handleSetArchitectInteriorDesignOfferStep, handleSetPmOfferStep} = props;
    const [userConsentChangeStatus, setUserConsentChangeStatus] = useState(IUserConsentChangeStatus.DEFAULT);
    const expendEventSentRef = useRef<boolean>(false);

    const {multiLeadSummaryItems, originalApplicationResponse} = leadModalStepState;

    const originalApplication = useSelector((store: IRPStore) => store.application[storeHash]);
    const {regionEstimatedPriceSummary} = useRegionEstimatedPrice({
        region: originalApplication?.offer?.detail?.region
    });
    const {architectOfferSummary, architectRegion} = useMarketplaceArchitectOfferForRegion({
        propertyId: originalApplication?.property?.detail?.id,
        offerId: originalApplication?.offer?.detail?.id
    });

    const userProfileData = useSelector((state: IRPStore) => state.user.profile.data);
    const canPropertyNotificationConsentBeChange =
        !!userProfileData &&
        !userProfileData.email_marketing_consent &&
        !userProfileData.phone_marketing_consent &&
        userProfileData.email === props.originalApplicationFormValues.email;
    const onPropertyNotificationConsentChange = async () => {
        if (!canPropertyNotificationConsentBeChange || userConsentChangeStatus !== IUserConsentChangeStatus.DEFAULT) {
            return;
        }

        if (!userProfileData.email_marketing_consent && !userProfileData.phone_marketing_consent) {
            setUserConsentChangeStatus(IUserConsentChangeStatus.IN_PROGRESS);
            await patchUserConsent({[MarketingConsentType.EMAIL]: true, [MarketingConsentType.PHONE]: true});
            await dispatch(forceFetchUserProfile());
            setUserConsentChangeStatus(IUserConsentChangeStatus.DONE);
        }
    };

    const {summaryItems, sentApplicationCount} = getLeadsSummaryIncludingMainLead({
        leadData,
        originalApplicationResponse,
        multiLeadSummaryItems
    });

    const onPmOfferClick = () => {
        multiLeadAdditionalOfferAnalytics.gtm.multiLeadAdditionalOfferEvent(AdditionalOfferCategoryGTMEventAction.PM);
        handleSetPmOfferStep();
    };

    const onArchitectPackagesClick = () => {
        multiLeadAdditionalOfferAnalytics.gtm.multiLeadAdditionalOfferEvent(AdditionalOfferCategoryGTMEventAction.ARCHITECTS);

        if (architectOfferSummary) {
            handleSetArchitectInteriorDesignOfferStep(architectOfferSummary, architectRegion);
        }
    };

    return (
        <div css={wrapperStyle}>
            <div css={[mb(4)]}>
                <Text variant="headline_3" mb="0.4rem" align="center" css={w100}>
                    Wysłano {formatApplicationCount(sentApplicationCount)}
                </Text>
                <Text variant="body_copy_2" align="center" css={w100}>
                    Co możesz zrobić teraz?
                </Text>
            </div>
            <div css={[summaryWrapperStyle, mb(2)]}>
                <Expandable
                    collapsedHeight={0}
                    customCollapseButton={({isExpanded, toggleCollapse}) => (
                        <div css={summaryCollapseHeaderStyle}>
                            <div css={infoColumnStyle}>
                                <div css={[iconWrapperStyle, backgroundColor("transparent")]}>
                                    <BrandCheckboxCheckedIcon size="4.8" wrapperSize="5.6" wrapperColor={theme.colors.primary} fill={theme.colors.secondary} />
                                </div>
                                <div css={textWrapperStyle}>
                                    <Text variant="headline_6" mb="0.4rem">
                                        Ceny są już dostępne
                                    </Text>
                                    <Text variant="body_copy_2">Zobacz ceny i porównaj oferty, na które wysłałeś zapytanie</Text>
                                </div>
                            </div>
                            <div css={flexAbsoluteCenter}>
                                <Button
                                    variant="none_secondary"
                                    type="button"
                                    iconRight={isExpanded ? ChevronUpIcon : ChevronDownIcon}
                                    onClick={() => {
                                        if (!isExpanded && !expendEventSentRef.current) {
                                            expendEventSentRef.current = true;
                                            multiLeadAdditionalOfferAnalytics.gtm.multiLeadAdditionalOfferEvent(AdditionalOfferCategoryGTMEventAction.EXPAND);
                                        }
                                        toggleCollapse();
                                    }}
                                    css={[ph(3)]}
                                >
                                    {!isMobile && (isExpanded ? "Zwiń" : "Rozwiń")}
                                </Button>
                            </div>
                        </div>
                    )}
                >
                    <MultiLeadApplicationSummary
                        applications={summaryItems}
                        regionEstimatedPriceSummary={regionEstimatedPriceSummary}
                        css={[applicationSummaryWrapper, pb(1)]}
                        boxStyle={backgroundColor("#fff")}
                    />
                    <div css={[p(0, 1.5, 0, 1.5), onDesktop(p(0, 3, 2, 3))]}>
                        <KMPromoBoxHorizontal />
                    </div>
                </Expandable>
            </div>
            <div css={promoBoxesWrapperStyle}>
                {canPropertyNotificationConsentBeChange && (
                    <PromoBoxHorizontal
                        iconSlot={<BrandHandclapIcon size="4.2" />}
                        title="Trzymaj rękę na pulsie"
                        description="Poinformujemy Cię o najnowszych i najlepszych okazjach mailem lub SMSem."
                        actionDescription="*Możesz dowolnie zmieniać ustawienia tych powiadomień na swoim koncie"
                        customButton={
                            <Button
                                variant="filled_primary"
                                onClick={onPropertyNotificationConsentChange}
                                isLoading={userConsentChangeStatus === IUserConsentChangeStatus.IN_PROGRESS}
                                disabled={userConsentChangeStatus !== IUserConsentChangeStatus.DEFAULT}
                                css={buttonStyle}
                            >
                                {userConsentChangeStatus === IUserConsentChangeStatus.DONE ? (
                                    <CheckIcon size="3" />
                                ) : (
                                    "Chcę otrzymać linki do najnowszych ofert"
                                )}
                            </Button>
                        }
                    />
                )}
                {architectOfferSummary && (
                    <PromoBoxHorizontal
                        iconSlot={<Image width="56" height="56" src={multiLeadArchitectPackagesPromoImg} alt="" />}
                        title="Poznaj koszty wykończenia"
                        description="Zobacz atrakcyjne pakiety wykończenia pod klucz z gwarancją ceny i terminu!"
                        actionBtnText="Sprawdź ceny wykończenia"
                        actionDescription="Dowiedz się, ile może kosztować wykończenie Twojego mieszkania"
                        onActionClick={onArchitectPackagesClick}
                    />
                )}
                <PromoBoxHorizontal
                    iconSlot={<Image width="56" height="56" src={multiLeadOffersPromoImg} alt="" />}
                    title="Uzyskaj dostęp do dodatkowych ofert"
                    description="Skorzystaj z doświadczenia naszych ekspertów rynku nieruchomości."
                    actionBtnText="Zobacz więcej ofert"
                    actionDescription="Współpracujemy z najlepszymi pośrednikami"
                    onActionClick={onPmOfferClick}
                />
            </div>
        </div>
    );
};

const wrapperStyle = (theme: Theme) => css`
    ${p(3)};
    overflow: auto;
    width: 100%;
    height: 100%;
    background-color: ${theme.colors.gray[100]};
`;

const summaryWrapperStyle = (theme: Theme) => css`
    border: 1px solid ${theme.colors.gray[500]};
    background-color: ${theme.colors.background[100]};
    ${borderRadius(1)};
`;

const summaryCollapseHeaderStyle = css`
    ${p(2)};
    order: -1;
    overflow: hidden;
    ${flex("center", "space-between")};
    flex-direction: row;
    width: 100%;

    ${onDesktop(css`
        ${p(3)};
    `)};
`;

const promoBoxesWrapperStyle = css`
    ${flex()};
    flex-direction: column;
    gap: ${calculateRemSize(2)};
`;

const applicationSummaryWrapper = css`
    ${mt(2)};
    ${mh(1.5)};
    flex: 1 1 100%;

    ${onDesktop(css`
        ${mh(3)};
    `)};
`;
