import {hitGoogleTagManager} from "../../tracking/google_tag_manager/init_google_tag_manager";

export enum AdditionalOfferCategoryGTMEventAction {
    PM = "Platforma mieszkaniowa ",
    ARCHITECTS = "Architekci",
    KM = "Konto mieszkaniowe",
    CLOSE = "closed",
    EXPAND = "expand"
}

const multiLeadAdditionalOfferEvent = (category: AdditionalOfferCategoryGTMEventAction) => {
    hitGoogleTagManager({event: "lead_tile_click", tile_category: category});
};

export const multiLeadAdditionalOfferAnalytics = {
    gtm: {
        multiLeadAdditionalOfferEvent
    }
};
