import {getOfferTypeName, getOfferTypeNameWhat} from "../../../offer/helpers/OfferType";
import {OfferType} from "../../../offer/types/OfferType";

interface IProps {
    offerType?: OfferType;
    offerName?: string;
    propertyNumber?: string;
    name?: string;
    rooms?: number;
    area?: number;
}

export const getGeneratedMessage = (props: IProps) => {
    const offerTypeName = props.offerType && getOfferTypeName(props.offerType);
    const offerTypeNameWhat = props.offerType && getOfferTypeNameWhat(props.offerType);
    const propertyInfo = props.propertyNumber ? ` ${props.propertyNumber}` : "";
    const roomInfo = props.rooms ? ` ${props.rooms}-pokojowego` : "";
    const roomInfoFooter = props.rooms ? ` ${props.rooms}-pokojowe` : "";
    // area +-10%
    const areaFactor = 0.1;
    const areaDiff = (props.area || 0) * areaFactor;
    const areaInfo = props.area ? ` o powierzchni ${Math.floor(props.area - areaDiff)}-${Math.floor(props.area + areaDiff)} m²` : "";

    const footerText = [
        `\n\nrynekpierwotny.pl radzi:`,
        `- Ponad połowa klientów (58%) nie pamięta nazwy inwestycji ani dewelopera, ale są zainteresowani zakupem. Uwzględnij to w komunikacji.`,
        `- Skontaktuj się telefonicznie jak najszybciej, aby zaprezentować dostępne mieszkania${roomInfoFooter}.`,
        `- Pamiętaj, że skuteczność kontaktu po godzinie 18 wzrasta aż o 60%.`,
        `- Po rozmowie wyślij ofertę mailem i potwierdź jej otrzymanie SMS-em. To ważne, ponieważ nawet 47% maili trafia do katalogu „spam” lub „oferty” i pozostaje nieprzeczytanych.`
    ].join(`\n`);

    return [
        `Klient ${props.name} poszukuje ${offerTypeNameWhat}${roomInfo}${areaInfo}, dostępnego w Państwa ofercie.`,
        `Najbardziej interesuje go ${offerTypeName}${propertyInfo} w inwestycji ${props.offerName}.`,
        footerText
    ].join(" ");
};
