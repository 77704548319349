import {css, Theme} from "@emotion/react";

import {flex, m, mr, p} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text-module";

const worldSideSrc = require("../../../images/world_side.png");

interface IProps {
    name: string;
    vendorName: string;
    vendorLogo?: string;
    description: string;
    mainImage?: {
        desktop?: string;
        mobile?: string;
    };
    distance?: number;
}

export const MarkerPopupWithDistance = (props: IProps) => {
    return (
        <div>
            <div css={imgHolder}>
                {props.vendorLogo ? <img src={props.vendorLogo} alt={props.vendorName} css={vendorLogoStyle} /> : null}
                <img src={props.mainImage?.desktop} alt={props.name} css={thumbnailStyle} />
            </div>

            <div css={contentHolderStyle}>
                <Text variant="info_txt_3" as="div" css={[m(0)]}>
                    {props.name}
                </Text>
                <Text variant="info_txt_3" as="div" css={[m(0, 0, 1)]} strong>
                    {props.description}
                </Text>

                {props.distance ? (
                    <Text variant="info_txt_3" as="div" css={[flex("center")]}>
                        <img src={worldSideSrc} alt="dystans" css={[mr(0.5)]} />
                        <span>{props.distance} km od Twojej inwestycji</span>
                    </Text>
                ) : null}
            </div>
        </div>
    );
};

const imgHolder = css`
    position: relative;
    height: 88px;
    width: 100%;
`;

const thumbnailStyle = css`
    object-fit: cover;
    width: 100%;
    height: 88px;
    margin: 0;
`;

const vendorLogoStyle = css`
    position: absolute;
    top: 8px;
    left: 8px;
    max-width: 46px;
`;

const contentHolderStyle = (theme: Theme) => css`
    ${p(1, 1.5, 1.5)};
    color: ${theme.colors.gray[700]};
`;
