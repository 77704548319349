import React, {HTMLAttributes} from "react";
import {css, Theme} from "@emotion/react";
import styled from "@emotion/styled";

import {backgroundColor, borderRadius, calculateRemSize, flex, mb, mr, mt, p, ph} from "@pg-design/helpers-css";
import {AreaIcon, FloorUpIcon, RoomsIcon} from "@pg-design/icons-module";
import {Image} from "@pg-design/image-module";
import {Text} from "@pg-design/text-module";
import {areaFormat, pluralize, priceFormat} from "@pg-mono/string-utils";

import {Selectable} from "../../../atoms/Selectable";

type IProps = {
    onSelect: (id: number) => void;
    property: {
        area: number;
        floor: number;
        id: number;
        number: string;
        offer: {
            main_image: {
                m_img_350x230: string;
            } | null;
            name: string;
        };
        price: number | null;
        rooms: number;
    };
    selected: number[];
} & Omit<HTMLAttributes<HTMLDivElement>, "property" | "onSelect" | "onChange">;

const roomsPlural = pluralize(["pokój", "pokoje", "pokoi"]);

export const SameOfferRecommendedProperty = (props: IProps) => {
    const {onSelect, property, selected: selectedIds, ...htmlProps} = props;
    const floor = property.floor === 0 ? "Parter" : `${property.floor} piętro`;
    const rooms = `${property.rooms} ${roomsPlural(property.rooms)}`;

    const selected = selectedIds.includes(property.id);

    return (
        <Selectable css={borderRadius()} onChange={() => onSelect(property.id)} selected={selected} {...htmlProps}>
            <Property selected={selected}>
                <Image
                    alt={`${property.offer.name} - mieszkanie ${property.number}`}
                    css={image}
                    src={property.offer.main_image?.m_img_350x230}
                    width="auto"
                    height="auto"
                />
                <div css={p(1, 2, 4, 1)}>
                    <Text as="div" variant="headline_5">
                        Mieszkanie {property.number}
                    </Text>
                    <div css={features}>
                        <IconLabel icon={<AreaIcon size="2.4" />} label={areaFormat(property.area)} />
                        <IconLabel icon={<RoomsIcon size="2.4" />} label={rooms} />
                        <IconLabel icon={<FloorUpIcon size="2.4" />} label={floor} />
                    </div>
                    <Text as="span" css={[price, !property.price && backgroundColor("transparent")]} variant="headline_5">
                        {property.price ? priceFormat(property.price) : null}
                    </Text>
                </div>
            </Property>
        </Selectable>
    );
};

const IconLabel = (props: {icon: React.ReactNode; label: React.ReactNode}) => {
    return (
        <div css={flex("center")}>
            <span css={[ph(0.5), mr()]}>{props.icon}</span>
            <Text as="span" strong variant="body_copy_2">
                {props.label}
            </Text>
        </div>
    );
};

const Property = styled.div<{selected: boolean}>`
    width: 32.8rem;
    border: 1px solid ${(props) => props.theme.colors.gray[500]};
    ${borderRadius()};
    ${flex()};
    flex-direction: column;
    overflow: hidden;
    transition: border-color 200ms;

    @media (min-width: ${(props) => props.theme.breakpoints.md}) {
        width: 100%;
        flex-direction: row;
    }

    ${(props) =>
        props.selected &&
        css`
            border-color: transparent;
        `}
`;

const image = (theme: Theme) => css`
    width: 33.6rem;
    height: 23rem;

    @media (min-width: ${theme.breakpoints.md}) {
        width: 26.4rem;
        height: 18rem;
    }
`;

const features = (theme: Theme) => css`
    ${flex()};
    column-gap: ${calculateRemSize(3)};
    ${mt()};
    ${mb(4)};

    @media (min-width: ${theme.breakpoints.md}) {
        ${mt(1.5)};
        ${mb(6)};
    }
`;

const price = (theme: Theme) => css`
    background-color: ${theme.colors.primary};
    ${p(1, 0.5)};
    min-height: 2.8rem;

    @media (min-width: ${theme.breakpoints.md}) {
        min-height: 3.2rem;
    }
`;
