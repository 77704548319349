import {Dispatch} from "redux";

import {fetchRecommendedOffers} from "../../../offer/detail/actions/fetch_recommended_offers";
import {fetchRecommendedProperties} from "../../../property/actions/fetch_recommended_properties";

export interface IFetchLeadRecommendationsParams {
    offerId: number | undefined | null;
    propertyId: number | undefined | null;
    numberOfRooms: number[] | undefined | null;
    excludedOfferIds: number[];
    dispatch: Dispatch;
    filters?: Record<string, string>;
}
export async function fetchLeadRecommendations(params: IFetchLeadRecommendationsParams) {
    const {offerId, propertyId, numberOfRooms, excludedOfferIds, dispatch, filters = {}} = params;

    // PropertyId will only exist if multilead was fetched for property application. Otherwise, it is an offer multilead.
    if (propertyId) {
        if (filters.max_price && offerId) {
            const recommendedProperties = await dispatch(fetchRecommendedProperties(propertyId, [], {...filters, offer: offerId}));

            return recommendedProperties.length ? recommendedProperties : await dispatch(fetchRecommendedProperties(propertyId, excludedOfferIds, filters));
        }

        return await dispatch(fetchRecommendedProperties(propertyId, excludedOfferIds, filters));
    }

    if (offerId) {
        return await dispatch(fetchRecommendedOffers(offerId, numberOfRooms, excludedOfferIds));
    }

    return null;
}
