import React, {ReactNode, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {css, Theme} from "@emotion/react";
import styled from "@emotion/styled";

import {AdvancedScroll} from "@pg-design/advanced-scroll";
import {borderRadius, calculateRemSize, m, mb, onDesktop, p, pb, pl, pr, w100} from "@pg-design/helpers-css";
import {useScrollableListItemTracking} from "@pg-mono/hooks";
import {useUserDevice} from "@pg-mono/user-device";

import {IRPStore} from "../../../../../app/rp_reducer";
import {RecommendedPropertyItem} from "../../../../../atoms/RecommendedPropertyItem";
import {notifyBugsnag} from "../../../../../errors/bugsnag/notify_bugsnag";
import {hitMultileadRecommendationShown} from "../../../../../lead/tracking/hit_multilead_recommendation_shown";
import {MultileadVersion} from "../../../../../lead/types/MultileadVersion";
import {RecommendationSource} from "../../../../../lead/types/RecommendationSource";
import {getOfferTypeNameCapital} from "../../../../../offer/helpers/OfferType";
import {IRecommendedProperty} from "../../../../../recommendations/types/IRecommendedProperty";
import {Country} from "../../../../../region/types/Country";
import {ViewType} from "../../../../../view_type/ViewType";
import {MultiLeadRecommendationType} from "../../../../types/MultiLeadRecommendationType";
import {RecommendedMap} from "./RecommendedMap";

interface IProps {
    listTopSlot?: ReactNode;
    offers: IRecommendedProperty[];
    onListScroll: (event: React.UIEvent) => void;
    isScrolledToBottom: boolean;
    selectedOffers: number[];
    handleChange: (viewType: ViewType, offerId: number) => void;
    originalApplicationGeoPoint: {coordinates: [number, number]} | null | undefined;
    originalApplicationCountry?: Country;
    originalApplicationEstimatedPrice?: string | null;
    handlePinClick: (viewType: ViewType | null, recommendedItemID: number) => void;
}

export const RecommendedPropertyList = (props: IProps) => {
    const [hoveredItemID, setHoveredItemID] = useState<null | number>(null);
    const listRef = useRef<HTMLDivElement>(null);
    const listWrap = useRef<HTMLUListElement>(null);
    const viewType = useSelector((store: IRPStore) => store.viewType.current);
    const {isMobile} = useUserDevice();

    const ignoredCn = useScrollableListItemTracking({
        scrollableListRef: listRef,
        directAncestorListRef: listWrap,
        listElementTrackingIdDatasetName: "trackId",
        handleListItemTrackingHit: (trackingId) => {
            hitMultileadRecommendationShown({
                recommendationId: trackingId,
                recommendationType: MultiLeadRecommendationType.PROPERTY,
                recommendationSource: RecommendationSource.DISTANCE,
                multileadVersion: MultileadVersion.RP_LIST_PROPERTY,
                viewType
            });
        },
        onError: (error) => {
            console.warn(error);
            notifyBugsnag(new Error(error), error);
        }
    });

    const onListItemHover = (itemID: number | null) => {
        setHoveredItemID(itemID);
    };

    return (
        <div css={modalWrapper}>
            <AdvancedScroll
                ref={listRef}
                height={440}
                contentStyle={leftSectionScrollContentStyle}
                scrollTopShadowStyle={contentTopShadow}
                scrollBottomShadowStyle={contentBottomShadow}
                disableScroll={!!isMobile}
                css={[leftSectionWrapper, w100]}
            >
                <ListWrapper onScroll={props.onListScroll} isScrolledToBottom={props.isScrolledToBottom} ref={listWrap}>
                    <div className={ignoredCn}>{props.listTopSlot}</div>
                    {props.offers.map((recommendedItem) => {
                        const isChecked = props.selectedOffers.includes(recommendedItem.id);
                        const offerType = getOfferTypeNameCapital(recommendedItem.offer.type);

                        return (
                            <RecommendedPropertyItem
                                key={recommendedItem.id}
                                data-track-id={recommendedItem.id}
                                property={recommendedItem}
                                offerType={offerType}
                                checked={isChecked}
                                onHover={onListItemHover}
                                handleChange={() => props.handleChange(ViewType.OFFER, recommendedItem.id)}
                            />
                        );
                    })}
                </ListWrapper>
            </AdvancedScroll>

            {props.originalApplicationGeoPoint && props.originalApplicationCountry && (
                <div css={mapWrapperStyle}>
                    <RecommendedMap
                        hoveredItemID={hoveredItemID}
                        originalGeoPoint={props.originalApplicationGeoPoint}
                        originalCountry={props.originalApplicationCountry}
                        originalOfferLabel={props.originalApplicationEstimatedPrice}
                        viewType={viewType}
                        handlePinClick={props.handlePinClick}
                        recommendations={props.offers}
                        recommendationType={MultiLeadRecommendationType.PROPERTY}
                        checkedList={props.selectedOffers}
                        css={mapStyle}
                    />
                </div>
            )}
        </div>
    );
};

interface IListWrapperProps {
    isScrolledToTop?: boolean;
    isScrolledToBottom?: boolean;
}

export const ListWrapper = styled.ul<IListWrapperProps>`
    ${m(0)};
    ${p(0)};
    list-style: none;
    position: relative;
    flex-basis: calc(50% - 4.8rem);

    @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
        ${pr(5)};
        ${pb(2)};
        max-height: inherit;
    }
`;

const leftSectionWrapper = (props: Theme) => css`
    ${mb(10)};

    @media (min-width: ${props.breakpoints.sm}) {
        ${mb(0)};
        ${pb(2)};
    }
`;

const leftSectionScrollContentStyle = css`
    ${pl(1.5)};
    ${onDesktop(pl(3))};
`;

const modalWrapper = (theme: Theme) => css`
    display: flex;
    flex-direction: column-reverse;

    @media (min-width: ${theme.breakpoints.sm}) {
        justify-content: space-between;
        gap: ${calculateRemSize(1)};
        flex-direction: row;
    }
`;

const mapWrapperStyle = (theme: Theme) => css`
    ${mb(3)};
    position: relative;
    width: 100%;

    @media (min-width: ${theme.breakpoints.sm}) {
        ${pr(3)};
        ${mb(0)};
        height: 440px;
        flex: 0 0 50%;
    }
`;

const mapStyle = (theme: Theme) => css`
    @media (min-width: ${theme.breakpoints.sm}) {
        overflow: hidden;
        ${borderRadius(1)};
    }
`;

const contentTopShadow = css`
    background: linear-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
`;

const contentBottomShadow = css`
    background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
`;
