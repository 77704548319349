import {IMarketplaceArchitectOfferSummary} from "../../architect_application/api/fetch_marketplace_architect_promoted_offers_by_region";
import {IRegionAncestor} from "../../offer/list/actions/offer_list_selected_data_actions";
import {IRecommendedOffer} from "../../recommendations/types/IRecommendedOffer";
import {IRecommendedProperty} from "../../recommendations/types/IRecommendedProperty";
import {IApplication} from "../types/IApplication";
import {IGoodbyeInfoStepPayload} from "../types/IGoodbyeInfoStepPayload";
import {IModalStepState} from "../types/IModalStepState";
import {IMultiLeadStepPayload} from "../types/IMultiLeadStepPayload";
import {IMultileadSummaryStepState} from "../types/IMultileadSummaryStepState";
import {INotificationConsentStepPayload} from "../types/INotificationConsentStepPayload";
import {ISetMultiLeadSummaryStepPayload} from "../types/ISetMultiLeadSummaryStepPayload";
import {LeadModalStep} from "../types/LeadModalStep";
import {IApplicationData} from "./application_reducer";

export enum ModalStepActionTypes {
    NEXT_STEP = "nextStep",
    CLOSE = "close",
    SET_IS_AUTORESPONDER_ENABLED = "setIsAutoresponderEnabled",
    OPEN = "open",
    UPDATE_LEAD_DATA = "updateLeadData",
    UPDATE_SINGLE_LEAD_RESPONSE_DATA = "updateSingleLeadResponse",
    SINGLE_LEAD_SUMMARY_STEP = "singleLeadSummaryStep",
    APPEND_MULTI_LEAD_APPLICATIONS = "appendMultiLeadApplications",
    SET_MULTILEAD_STEP = "setMultileadStep",
    SET_MULTILEAD_SUMMARY_STEP = "setMultileadSummaryStep",
    SET_NOTIFICATION_CONSENT_STEP = "setNotificationConsentStep",
    SET_GOODBYE_INFO_STEP = "setGoodbyeInfoStep",
    SET_CLOSE = "setClose",
    SET_USER_BUDGET_STEP = "setUserBudgetStep",
    SET_USER_BUDGET_MULTI_LEAD_STEP = "setUserBudgetMultileadStep",
    SET_USER_BUDGET_NO_RECOMMENDATIONS_STEP = "setUserBudgetNoRecommendationsStep"
}

export interface IApplicationSentPayload {
    originalApplicationResponse?: IApplication | null;
    recommendedOffers?: IRecommendedProperty[] | IRecommendedOffer[] | null;
    recommendationsWithPriceFilter: boolean;
    crossPromoData?: ICrossPromoData | null;
}

interface IRecommendationsSendPayload {
    originalApplicationResponse?: IApplication | null;
    appliedRecommendedOffers?: number[];
}

interface IIsAutoresponderEnabledPayload {
    isAutoresponderEnabled: boolean;
}

interface IForceClosePayload {
    forceClose: boolean;
}

interface IShowPMOfferPayload {
    showPmOffer: boolean;
}

interface IArchitectOfferDetailsPayload {
    architectOfferDetails: IMarketplaceArchitectOfferSummary;
    architectRegion: IRegionAncestor | null;
}

type IPayload =
    | IApplicationSentPayload
    | IRecommendationsSendPayload
    | IIsAutoresponderEnabledPayload
    | IForceClosePayload
    | IArchitectOfferDetailsPayload
    | IUpdateLeadDataAction
    | IShowPMOfferPayload
    | IMultileadSummaryStepState
    | IMultiLeadStepPayload
    | INotificationConsentStepPayload
    | undefined;

interface INextStepAction {
    type: ModalStepActionTypes.NEXT_STEP;
    payload?: IPayload;
}

interface IOpenAction {
    type: ModalStepActionTypes.OPEN;
}

interface ICloseAction {
    type: ModalStepActionTypes.CLOSE;
    payload: IForceClosePayload;
}

interface ISetIsAutoresponderEnabledAction {
    type: ModalStepActionTypes.SET_IS_AUTORESPONDER_ENABLED;
    payload: IIsAutoresponderEnabledPayload;
}

interface IUpdateLeadDataAction {
    type: ModalStepActionTypes.UPDATE_LEAD_DATA;
    payload: IApplicationData;
}

interface IUpdateSingleLeadResponseAction {
    type: ModalStepActionTypes.UPDATE_SINGLE_LEAD_RESPONSE_DATA;
    payload: {
        originalApplicationResponse: IApplication | null;
    };
}

interface ISetSingleLeadSummaryStepAction {
    type: ModalStepActionTypes.SINGLE_LEAD_SUMMARY_STEP;
    payload: {
        originalApplicationResponse: IApplication | null;
    };
}

interface ISetMultileadSummaryStepAction {
    type: ModalStepActionTypes.SET_MULTILEAD_SUMMARY_STEP;
    payload?: ISetMultiLeadSummaryStepPayload;
}

interface IAppendMultiLeadApplicationsAction {
    type: ModalStepActionTypes.APPEND_MULTI_LEAD_APPLICATIONS;
    payload: Pick<IMultileadSummaryStepState, "multiLeadSummaryItems">;
}

interface ISetMultiLeadStepAction {
    type: ModalStepActionTypes.SET_MULTILEAD_STEP;
    payload: IMultiLeadStepPayload;
}

interface ISetNotificationConsentStepAction {
    type: ModalStepActionTypes.SET_NOTIFICATION_CONSENT_STEP;
    payload: INotificationConsentStepPayload;
}

interface ISetGoodbyeInfoStepAction {
    type: ModalStepActionTypes.SET_GOODBYE_INFO_STEP;
    payload: IGoodbyeInfoStepPayload;
}

interface ISetCloseAction {
    type: ModalStepActionTypes.SET_CLOSE;
}

interface ISetUserBudgetStepAction {
    type: ModalStepActionTypes.SET_USER_BUDGET_STEP;
}

interface ISetUserBudgetMultiLeadStepAction {
    type: ModalStepActionTypes.SET_USER_BUDGET_MULTI_LEAD_STEP;
    payload: Pick<IModalStepState, "originalApplicationResponse" | "recommendedOffers" | "shouldCloseOnOverlayClick" | "recommendationsWithPriceFilter">;
}

interface ISetUserBudgetNoRecommendationsStepAction {
    type: ModalStepActionTypes.SET_USER_BUDGET_NO_RECOMMENDATIONS_STEP;
}

export const initialStepState: IModalStepState = {
    step: LeadModalStep.APPLICATION_FORM,
    originalApplicationResponse: null,
    recommendedOffers: null,
    appliedRecommendedOffers: [],
    multiLeadSummaryItems: [],
    multiLeadProperty: null,
    // TODO: Do we actually use isClosing ?
    isClosing: false,
    shouldCloseOnOverlayClick: true,
    isAutoresponderEnabled: false,
    crossPromoData: {
        getHomeOffersCount: 0,
        geoPoint: "",
        locationName: ""
    },
    recommendationsWithPriceFilter: false,
    architectOfferDetails: null,
    architectRegion: null,
    leadData: null
};

const CLOSING_STATE = {
    ...initialStepState,
    step: LeadModalStep.CLOSE
};

export interface ICrossPromoData {
    getHomeOffersCount: number;
    geoPoint: string;
    roomsNumber?: number[];
    locationName: string;
    isProperty?: boolean;
}

type IModalStepAction =
    | INextStepAction
    | ICloseAction
    | ISetIsAutoresponderEnabledAction
    | IOpenAction
    | IUpdateLeadDataAction
    | IUpdateSingleLeadResponseAction
    | ISetSingleLeadSummaryStepAction
    | ISetMultileadSummaryStepAction
    | IAppendMultiLeadApplicationsAction
    | ISetMultiLeadStepAction
    | ISetNotificationConsentStepAction
    | ISetGoodbyeInfoStepAction
    | ISetCloseAction
    | ISetUserBudgetStepAction
    | ISetUserBudgetMultiLeadStepAction
    | ISetUserBudgetNoRecommendationsStepAction;

export const modalStepReducer = (state: IModalStepState, action: IModalStepAction): IModalStepState => {
    switch (action.type) {
        case ModalStepActionTypes.NEXT_STEP: {
            return nextAfterStep(state, action.payload);
        }
        case ModalStepActionTypes.OPEN: {
            return {...state, step: LeadModalStep.APPLICATION_FORM};
        }
        case ModalStepActionTypes.CLOSE: {
            return closeAfterStep({...state, forceClose: action.payload.forceClose});
        }
        case ModalStepActionTypes.SET_IS_AUTORESPONDER_ENABLED: {
            return {
                ...state,
                isAutoresponderEnabled: action.payload.isAutoresponderEnabled
            };
        }
        case ModalStepActionTypes.UPDATE_LEAD_DATA: {
            return {
                ...state,
                leadData: action.payload
            };
        }
        case ModalStepActionTypes.UPDATE_SINGLE_LEAD_RESPONSE_DATA: {
            return {
                ...state,
                originalApplicationResponse: action.payload.originalApplicationResponse
            };
        }
        case ModalStepActionTypes.SINGLE_LEAD_SUMMARY_STEP: {
            return {
                ...state,
                step: LeadModalStep.SINGLE_LEAD_SUMMARY,
                originalApplicationResponse: action.payload.originalApplicationResponse
            };
        }
        case ModalStepActionTypes.SET_MULTILEAD_SUMMARY_STEP: {
            if (action.payload) {
                return {
                    ...state,
                    step: LeadModalStep.MULTILEAD_SUMMARY,
                    multiLeadSummaryItems: [...state.multiLeadSummaryItems, ...action.payload.multiLeadSummaryItems],
                    multiLeadProperty: action.payload.multiLeadProperty
                };
            }

            return {
                ...state,
                step: LeadModalStep.MULTILEAD_SUMMARY
            };
        }
        case ModalStepActionTypes.SET_USER_BUDGET_STEP: {
            return {
                ...state,
                step: LeadModalStep.USER_BUDGET
            };
        }
        case ModalStepActionTypes.SET_USER_BUDGET_MULTI_LEAD_STEP: {
            return {
                ...state,
                ...action.payload,
                step: LeadModalStep.USER_BUDGET_MULTI_LEAD
            };
        }
        case ModalStepActionTypes.SET_USER_BUDGET_NO_RECOMMENDATIONS_STEP: {
            return {
                ...state,
                step: LeadModalStep.USER_BUDGET_NO_RECOMMENDATIONS
            };
        }
        case ModalStepActionTypes.APPEND_MULTI_LEAD_APPLICATIONS: {
            return {
                ...state,
                multiLeadSummaryItems: [...state.multiLeadSummaryItems, ...action.payload.multiLeadSummaryItems]
            };
        }
        case ModalStepActionTypes.SET_MULTILEAD_STEP: {
            return {
                ...state,
                step: LeadModalStep.MULTILEAD
            };
        }
        case ModalStepActionTypes.SET_NOTIFICATION_CONSENT_STEP: {
            return {
                ...state,
                step: LeadModalStep.NOTIFICATION_CONSENT,
                originalApplicationResponse: action.payload.originalApplicationResponse
            };
        }
        case ModalStepActionTypes.SET_GOODBYE_INFO_STEP: {
            return {
                ...state,
                step: LeadModalStep.GOODBYE_INFO
            };
        }
        case ModalStepActionTypes.SET_CLOSE:
        default: {
            return CLOSING_STATE;
        }
    }
};

const nextAfterStep = (state: IModalStepState, payload: IPayload) => {
    switch (state.step) {
        case LeadModalStep.MULTILEAD: {
            if (payload && "multiLeadSummaryItems" in payload && payload.multiLeadSummaryItems.length) {
                return {
                    ...state,
                    multiLeadSummaryItems: [...state.multiLeadSummaryItems, ...payload.multiLeadSummaryItems],
                    multiLeadProperty: payload.multiLeadProperty,
                    step: LeadModalStep.MULTILEAD_SUMMARY
                };
            }

            if (payload && "originalApplicationResponse" in payload && !payload.originalApplicationResponse?.new_property_notification_consent) {
                return {
                    ...state,
                    originalApplicationResponse: payload.originalApplicationResponse as IApplication,
                    step: LeadModalStep.NOTIFICATION_CONSENT
                };
            }

            return CLOSING_STATE;
        }

        case LeadModalStep.MULTILEAD_SUMMARY: {
            if (payload && "architectOfferDetails" in payload) {
                return {
                    ...state,
                    architectOfferDetails: payload.architectOfferDetails,
                    architectRegion: payload?.architectRegion,
                    step: LeadModalStep.ARCHITECT_OFFER
                };
            }

            if (payload && "showPmOffer" in payload && payload.showPmOffer) {
                return {
                    ...state,
                    step: LeadModalStep.PM_OFFER
                };
            }

            return CLOSING_STATE;
        }

        case LeadModalStep.SINGLE_LEAD_SUMMARY: {
            if (payload && "architectOfferDetails" in payload) {
                return {
                    ...state,
                    architectOfferDetails: payload.architectOfferDetails,
                    architectRegion: payload?.architectRegion,
                    step: LeadModalStep.ARCHITECT_OFFER
                };
            }

            return CLOSING_STATE;
        }

        case LeadModalStep.ARCHITECT_OFFER: {
            if (payload && "architectOfferDetails" in payload) {
                return {
                    ...state,
                    architectOfferDetails: payload.architectOfferDetails,
                    architectRegion: payload?.architectRegion,
                    step: LeadModalStep.ARCHITECT_MULTILEAD
                };
            }

            return CLOSING_STATE;
        }

        case LeadModalStep.PM_OFFER: {
            return {
                ...state,
                step: LeadModalStep.PM_APPLIED_OFFER
            };
        }

        default: {
            return CLOSING_STATE;
        }
    }
};

const closeAfterStep = (state: IModalStepState) => {
    switch (state.step) {
        case LeadModalStep.APPLICATION_FORM: {
            if (state.isAutoresponderEnabled || state.forceClose) {
                return CLOSING_STATE;
            }

            return {
                ...state,
                isClosing: true,
                step: LeadModalStep.GOODBYE_INFO
            };
        }
        case LeadModalStep.SINGLE_LEAD_SUMMARY: {
            if (!state.originalApplicationResponse?.new_property_notification_consent) {
                return {
                    ...state,
                    step: LeadModalStep.NOTIFICATION_CONSENT
                };
            }

            return CLOSING_STATE;
        }
        case LeadModalStep.MULTILEAD_SUMMARY: {
            if (!state.originalApplicationResponse?.new_property_notification_consent) {
                return {
                    ...state,
                    step: LeadModalStep.NOTIFICATION_CONSENT
                };
            }

            return CLOSING_STATE;
        }
        default: {
            return CLOSING_STATE;
        }
    }
};
