export const recommendationsDistanceInKm = 2;

export const vendorRecommendationsDistanceInKm = 2;

export const MULTI_LEAD_ESTIMATE_PRICE_DELAY = 2000;

export const PRICE_OPTIONS = [
    {
        label: "do 250 000 zł",
        value: {
            upper: 250000
        }
    },
    {
        label: "do 500 000 zł",
        value: {
            upper: 500000
        }
    },
    {
        label: "do 800 000 zł",
        value: {
            upper: 800000
        }
    },
    {
        label: "do 1 000 000 zł",
        value: {
            upper: 1000000
        }
    },
    {
        label: "powyżej 1 mln zł",
        value: {
            lower: 1000000
        }
    }
];

export const PRICE_STEP_CONFIG = {
    min: 250000,
    max: 3000000,
    step: 50000
};

export const DEFAULT_PRICE_OPTION = PRICE_OPTIONS[3];

export const REVEALED_PRICE_ESTIMATED_UPPER_FACTOR = 1.1;

export const REVEALED_PRICE_ESTIMATED_LOWER_FACTOR = 0.95;
