import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import {css, Theme, useTheme} from "@emotion/react";

import {CenterBox} from "@pg-design/grid-module";
import {calculateRemSize, flexAlignCenter, flexDirection, h100, mb, mt, p, pr, prettyScroll, w100} from "@pg-design/helpers-css";
import {BrandCheckboxCheckedIcon} from "@pg-design/icons-module";
import {Loader} from "@pg-design/loader";
import {Text} from "@pg-design/text-module";
import {rpAppLink} from "@pg-mono/rp-routes";

import {IRPStore} from "../../app/rp_reducer";
import {IMarketplaceArchitectOfferSummary} from "../../architect_application/api/fetch_marketplace_architect_promoted_offers_by_region";
import {ArchitectOffersInfoBox} from "../../architect_application/components/parts/ArchitectOffersInfoBox";
import {useMarketplaceArchitectOfferForRegion} from "../../architect_application/hooks/use_marketplace_architect_offer_summary";
import {IRegionAncestor} from "../../offer/list/actions/offer_list_selected_data_actions";
import {RecommendedPropertyApplicationsType} from "../types/recommended_property_applications_type";
import {EstimatedPricePlacement} from "../utils/conditional_prices_analytics";
import {EstimatedPriceModalGTMEvent, estimatedPricesAnalytics} from "../utils/estimated_prices_analytics";
import {AppliedProperty} from "./AppliedProperty";

interface IProps {
    storeHash: string;
    onShowArchitectOfferButtonClick: (architectData: IMarketplaceArchitectOfferSummary, architectRegion: IRegionAncestor | null) => void;
}

export const AppliedProperties = (props: IProps) => {
    const theme = useTheme();

    const mainLeadData = useSelector((store: IRPStore) => store.application[props.storeHash]);

    const application = mainLeadData?.application;
    const offer = mainLeadData?.offer?.detail;
    const property = mainLeadData?.property?.detail;
    const {architectOfferSummary, isArchitectOfferLoading, architectRegion} = useMarketplaceArchitectOfferForRegion({
        propertyId: property?.id,
        offerId: offer?.id
    });

    // TODO: Currently appliedRecommendedApplications are not set anywhere, we have MultileadSentSummary instead, consult with business
    const appliedRecommendedApplications: RecommendedPropertyApplicationsType[] = [];
    const plural = appliedRecommendedApplications.length + Number(Boolean(application)) > 1;

    useEffect(() => {
        estimatedPricesAnalytics.gtm.priceModalEvent(EstimatedPriceModalGTMEvent.CURRENT_PRICE_MODAL_SHOW, {
            price_placement: EstimatedPricePlacement.MULTILEAD
        });

        return () => {
            estimatedPricesAnalytics.gtm.priceModalEvent(EstimatedPriceModalGTMEvent.CURRENT_PRICE_MODAL_CLOSE);
        };
    }, []);

    const onArchitectOfferButtonClick = () => {
        if (architectOfferSummary) {
            props.onShowArchitectOfferButtonClick(architectOfferSummary, architectRegion);
        }
    };

    const architectOfferBoxButtonProps = architectOfferSummary
        ? {
              onClick: onArchitectOfferButtonClick
          }
        : {
              href: architectRegion?.slug ? rpAppLink.architect.city({regionSlug: architectRegion?.slug}) : rpAppLink.architect.list()
          };

    const hasAppliedProperties = (!!application && !!offer && !!property) || appliedRecommendedApplications.length > 0;

    return (
        <div css={[flexAlignCenter, flexDirection("column"), p(3, 0, 3, 3), h100]}>
            <div css={[flexAlignCenter, flexDirection("column"), w100, wrapperWithScroll, pr(3)]}>
                <BrandCheckboxCheckedIcon size="9.6" wrapperColor={theme.colors.primary} wrapperSize="9.6" css={flexFixedHeightStyle} />

                <Text align="center" css={[mt(2)]} mb="1.6rem" variant="headline_3">
                    Twoje {plural ? "zapytania zostały" : "zapytanie zostało"} wysłane
                </Text>

                {hasAppliedProperties && (
                    <div css={sentLeadsWrap}>
                        <div css={pricesCopy}>
                            <Text variant="body_copy_1">Na podstawie ostatnich aktualnych danych cena nieruchomości wynosi:</Text>
                        </div>
                        <div css={appliedProperties}>
                            {!!application && !!offer && !!property && (
                                <AppliedProperty application={application} property={{...property, offer, region: offer.region}} />
                            )}

                            {appliedRecommendedApplications.map(([application, recommendedApplication], index) => {
                                return <AppliedProperty key={index} application={application} property={recommendedApplication} />;
                            })}
                        </div>
                    </div>
                )}

                <Text align="center" css={expectContactMessage}>
                    Oczekuj na kontakt od dewelopera, który poinformuje Cię o dokładniej cenie.
                </Text>
            </div>

            <div css={[flexFixedHeightStyle, pr(3)]}>
                {isArchitectOfferLoading ? (
                    <CenterBox>
                        <Loader size="md" />
                    </CenterBox>
                ) : (
                    <ArchitectOffersInfoBox {...architectOfferBoxButtonProps} />
                )}
            </div>
        </div>
    );
};

const sentLeadsWrap = (theme: Theme) => css`
    background-color: ${theme.colors.background[100]};
    width: 100%;
    ${mb(2)};
`;

const expectContactMessage = css`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
`;

const pricesCopy = (theme: Theme) => css`
    background-color: ${theme.colors.background[100]};
    ${p(2, 2, 0, 2)};
`;

const wrapperWithScroll = css`
    flex: 1 1 auto;
    overflow: auto;
    ${prettyScroll()};
`;

const flexFixedHeightStyle = css`
    flex: 0 0 auto;
`;

const appliedProperties = (theme: Theme) => css`
    ${w100};

    & > div:not(:last-of-type):after {
        content: "";
        position: absolute;
        left: ${calculateRemSize(2)};
        bottom: 0;
        height: 1px;
        width: calc(100% - 3.2rem);
        background-color: ${theme.colors.gray[400]};
    }
`;
