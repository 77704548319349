import React, {ReactElement} from "react";
import {css, Theme} from "@emotion/react";

import {Button} from "@pg-design/button";
import {borderRadius, calculateRemSize, flex, flexAbsoluteCenter, mb, p} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text";

interface IProps {
    pictogram: ReactElement;
    title: string | ReactElement;
    description: string;
    buttonText: string;
    onClick: () => void;
    className?: string;
}

export const PromoBoxWithPictogram = (props: IProps) => {
    return (
        <div css={infoBoxStyle} className={props.className}>
            <div css={infoBoxPictogramWrapperStyle}>{props.pictogram}</div>
            <div css={infoBoxTextWrapperStyle}>
                <Text variant="headline_5" css={mb(2)}>
                    {props.title}
                </Text>
                <Text variant="body_copy_2">{props.description}</Text>
            </div>
            <Button variant="filled_secondary" onClick={props.onClick}>
                {props.buttonText}
            </Button>
        </div>
    );
};

const infoBoxStyle = (theme: Theme) => css`
    ${flex()};
    flex: 1 1 100%;
    flex-direction: column;
    gap: ${calculateRemSize(2)};
    ${borderRadius(2)};
    ${p(2)};
    background-color: ${theme.colors.background[200]};
`;

const infoBoxPictogramWrapperStyle = css`
    ${flexAbsoluteCenter};
    height: 18rem;
`;

const infoBoxTextWrapperStyle = css`
    flex: 1 1 100%;
    height: 100%;
`;
