import React from "react";
import {useTheme} from "@emotion/react";

import {flexAbsoluteCenter, flexDirection, gap, maxWidth, mb, mr, pl, pointer, underline} from "@pg-design/helpers-css";
import {BrandCheckboxCheckedIcon, InfoIcon} from "@pg-design/icons-module";
import {Text} from "@pg-design/text-module";

interface IProps {
    openDataChanging: () => void;
    isSuccessfulSubmit: boolean;
}

export const MultileadArchitectTopSection = (props: IProps) => {
    const theme = useTheme();

    return (
        <div css={[mb(3)]}>
            {props.isSuccessfulSubmit ? (
                <div css={[flexAbsoluteCenter, flexDirection("column"), gap(3)]}>
                    <BrandCheckboxCheckedIcon size="9.6" wrapperSize="9.6" wrapperColor={theme.colors.primary} />
                    <Text as="p" variant="headline_3" align="center">
                        Twoje zapytania zostały wysłane.
                    </Text>
                </div>
            ) : (
                <div css={pl(0.5)}>
                    <Text as="p" variant="headline_6" css={[mb(1), maxWidth("90%")]}>
                        Wybierz i sprawdź inne konkurencyjne oferty firm świadczących usługi w Twojej okolicy
                    </Text>
                    <Text as="div" variant="info_txt_1" css={mb(1)} color={theme.colors.gray[700]}>
                        <InfoIcon size="1.6" wrapperSize="1.6" css={mr(0.5)} />
                        Użytkownicy, którzy wysłali zapytanie na 3 oferty byli w stanie wynegocjować najlepsze warunki.
                    </Text>

                    <Text variant="body_copy_2" onClick={() => props.openDataChanging()} css={[pointer, underline]}>
                        Zmień dane zapytania
                    </Text>
                </div>
            )}
        </div>
    );
};
