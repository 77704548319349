import React from "react";
import {css, Theme} from "@emotion/react";

import {Button} from "@pg-design/button";
import {borderRadius, calculateRemSize, flex, flexDirection, mr, mt, onDesktop, p} from "@pg-design/helpers-css";
import {BrandWallPaintRollerIcon} from "@pg-design/icons-module";
import {Text} from "@pg-design/text-module";
import {replaceSpaceWithNonBreakingSpace} from "@pg-mono/string-utils";

interface IProps {
    href?: string;
    target?: string;
    onClick?: () => void;
}

export const ArchitectOffersInfoBox = (props: IProps) => {
    return (
        <div css={architectOfferInfoBox}>
            <Text as="div" variant="headline_5" css={architectOfferBoxTextColumnStyle}>
                <div css={architectSectionIconStyle}>
                    <BrandWallPaintRollerIcon size="4.4" />
                </div>
                <span>{`Nasi użytkownicy urządzają mieszkania ${replaceSpaceWithNonBreakingSpace("z gwarancją")} ceny ${replaceSpaceWithNonBreakingSpace(
                    "i terminu"
                )}!`}</span>
            </Text>
            <Button href={props.href} target={props.target} variant="filled_secondary" onClick={props.onClick} css={architectActionButtonStyle}>
                Zobacz naszą ofertę
            </Button>
        </div>
    );
};

const architectOfferInfoBox = (theme: Theme) => css`
    background-color: ${theme.colors.primary};
    ${borderRadius(2)};
    ${flex()};
    flex-direction: column;
    gap: ${calculateRemSize(3)};
    ${p(2)};
    ${mt(3)};
    width: 100%;

    ${onDesktop(css`
        flex-direction: row;
    `)};
`;

const architectOfferBoxTextColumnStyle = css`
    ${flex("center", "flex-start")};
    ${flexDirection("row")};
    flex: 1 1 auto;
    user-select: none;
    pointer-events: none;
`;

const architectSectionIconStyle = css`
    ${flex("center", "center")};
    flex: 0 0 auto;
    width: 4.4rem;
    height: 4.4rem;
    ${mr(2)};
`;

const architectActionButtonStyle = css`
    flex: 1 1 auto;
    width: 100%;

    ${onDesktop(css`
        flex: 0 0 26rem;
        width: 26rem;
    `)};
`;
