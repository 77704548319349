import {Dispatch} from "redux";

import {toJsonValues} from "../../../form/translate/to_json_values";
import {addAppliedOffers} from "../../../offer/map/actions/add_applied_offers";
import {OfferType} from "../../../offer/types/OfferType";
import {IRecommendedProperty} from "../../../recommendations/types/IRecommendedProperty";
import {ViewType} from "../../../view_type/ViewType";
import {postRecommendedApplication} from "../../actions/post_recommended_application";
import {IApplicationAdditionalQuestionTypes} from "../../components/application_form/ApplicationFormContext";
import {applicationFormFields} from "../../constants/application_form";
import {IApplication} from "../../types/IApplication";
import {IApplicationFormValues} from "../../types/IApplicationFormValues";
import {MultiLeadRecommendationType} from "../../types/MultiLeadRecommendationType";
import {RecommendedPropertyApplicationsType} from "../../types/recommended_property_applications_type";
import {ApplicationSource, ApplicationSourceSection} from "../ApplicationSource";
import {addOffersToStorageSentApplication} from "../manage_storage_sent_applications";
import {addPropertiesToStorageSentApplication} from "../property_application_storage";
import {getGeneratedMessage} from "../text_generator/get_generated_message";
import {report} from "./analytics_events";

// Data used in reporting purposes both by backend and platforms like algolytics tracker and GTM
interface IPropertyMultiApplicationMetadata {
    viewType: ViewType | null;
    source?: ApplicationSource;
    sourceSection: ApplicationSourceSection;
    isAuthenticated: boolean;
    application: IApplication | null | undefined;
    refererApplicationUUID: string | undefined;
    originalFormParams: {
        rooms: number[];
        questions?: IApplicationAdditionalQuestionTypes[];
    };
    recommendationsWithPriceFilter: boolean;
}

interface ISingleApplicationPayload {
    formValues: IApplicationFormValues;
    recommendedProperty: {
        id: number;
        number: string;
        price?: number | null;
        area: number;
        rooms: number;
        offer: {configuration: {is_autoresponder_enabled: boolean}; name: string; type: OfferType};
    };
}

const sendPropertyApplication =
    (payload: ISingleApplicationPayload, metadata: IPropertyMultiApplicationMetadata) =>
    (dispatch: Dispatch): Promise<IApplication | void> => {
        const jsonValues = toJsonValues(applicationFormFields, payload.formValues);

        const source = metadata.source || ApplicationSource.RecommendedProperty;

        const text = getGeneratedMessage({
            offerType: payload.recommendedProperty.offer.type,
            offerName: payload.recommendedProperty.offer.name,
            propertyNumber: payload.recommendedProperty.number,
            name: payload.formValues.name,
            rooms: payload.recommendedProperty.rooms,
            area: payload.recommendedProperty.area
        });

        const applicationData: Partial<IApplication> = {
            referer_application_uuid: metadata.refererApplicationUUID,
            source,
            source_section: metadata.sourceSection,
            property: payload.recommendedProperty.id,
            ...jsonValues,
            text
        };

        return postRecommendedApplication(applicationData)(
            // pass dispatch instead of wrap because of return type
            dispatch
        );
    };

interface IPropertyMultiApplicationPayload {
    recommendedProperties: IRecommendedProperty[];
    formValues: IApplicationFormValues;
}

export const sendMultiplePropertyApplications =
    (payload: IPropertyMultiApplicationPayload, metadata: IPropertyMultiApplicationMetadata, customSourceSection?: string) =>
    (dispatch: Dispatch): Promise<RecommendedPropertyApplicationsType[]> =>
        Promise.all(
            payload.recommendedProperties.map((property) =>
                sendPropertyApplication(
                    {
                        recommendedProperty: property,
                        formValues: payload.formValues
                    },
                    metadata
                )(
                    // pass dispatch instead of wrap to properly catch errors
                    dispatch
                )
            )
        )
            .then((response) => {
                if (!response.filter(Boolean).length) {
                    return [];
                }

                const offerIDs = response.reduce<number[]>((acc, application) => {
                    return application ? acc.concat(application.offer) : acc;
                }, []);

                addOffersToStorageSentApplication(
                    payload.recommendedProperties.map((property) => {
                        return {
                            id: property.offer.id,
                            coordinates: property.offer.geo_point.coordinates,
                            country: property.region.country,
                            regionId: property.region.parent?.id
                        };
                    })
                );

                addPropertiesToStorageSentApplication(payload.recommendedProperties.map((property) => property.id));

                if (metadata.isAuthenticated) {
                    dispatch(addAppliedOffers(offerIDs));
                }

                const applications = response.reduce<RecommendedPropertyApplicationsType[]>((acc, application) => {
                    if (application) {
                        const recommendedProperty = payload.recommendedProperties.find(({id}) => id === application.property);

                        report.singleApplicationSuccess(application, metadata.sourceSection, metadata.viewType);

                        if (recommendedProperty) {
                            acc.push([application, recommendedProperty]);
                        }
                    }
                    return acc;
                }, []);

                report.finishedSending(
                    MultiLeadRecommendationType.PROPERTY,
                    applications,
                    metadata.viewType,
                    metadata.application,
                    metadata.recommendationsWithPriceFilter,
                    customSourceSection
                );

                return applications;
            })
            .catch(() => {
                return [];
            });
