import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {css} from "@emotion/react";

import {flex, onDesktop, p, zIndex} from "@pg-design/helpers-css";

import {IRPStore} from "../../../app/rp_reducer";
import {CloseIconButton} from "../../../atoms/CloseIconButton";
import {hitMultileadRecommendationShown} from "../../../lead/tracking/hit_multilead_recommendation_shown";
import {MultileadVersion} from "../../../lead/types/MultileadVersion";
import {RecommendationSource} from "../../../lead/types/RecommendationSource";
import {IRecommendedOffer} from "../../../recommendations/types/IRecommendedOffer";
import {IRecommendedProperty} from "../../../recommendations/types/IRecommendedProperty";
import {patchUserPreferences} from "../../../user/actions/patch_user_preferences";
import {IApplicationData} from "../../reducers/application_reducer";
import {MultiLeadRecommendationType} from "../../types/MultiLeadRecommendationType";
import {conditionalPricesAnalytics, PriceModalGTMEvent} from "../../utils/conditional_prices_analytics";
import {isAbroad} from "../../utils/is_abroad";
import {fetchSimpleMultiLeadRecommendations} from "../../utils/recommendations/fetch_simple_multilead_recommendations";
import {ApplicationPropertyPriceUserBudget, ApplicationUserBudgetFormValuesType} from "./ApplicationPropertyPriceUserBudget";

interface IProps {
    leadData: IApplicationData;
    setUserBudgetMultiLeadStep: (recommendedProperties: IRecommendedProperty[]) => void;
    setDefaultMultiLeadStep: (recommendations: IRecommendedProperty[] | IRecommendedOffer[]) => void;
    setUserBudgetNoRecommendationsStep: () => void;
    onSuccessfulSubmit?: () => void;
    recommendationSource: RecommendationSource;
    onClose(): void;
}

export const ApplicationPropertyPriceUserBudgetModal = (props: IProps) => {
    const {leadData, setUserBudgetMultiLeadStep, setDefaultMultiLeadStep, setUserBudgetNoRecommendationsStep, onSuccessfulSubmit, recommendationSource} = props;
    const isAuthenticated = useSelector((state: IRPStore) => state.isAuthenticated);
    const currentViewType = useSelector((store: IRPStore) => store.viewType.current);
    const dispatch = useDispatch();

    const handleUserBudgetSubmit = async (values: ApplicationUserBudgetFormValuesType) => {
        const max_price = values.max_price.replace(/\s/g, "");

        if (isAuthenticated) {
            dispatch(patchUserPreferences({max_price: Number(max_price)}));
        }

        conditionalPricesAnalytics.gtm.priceModalEvent(PriceModalGTMEvent.PRICE_ADD, {user_price: max_price});

        const offer = leadData?.offer?.detail;
        const property = leadData?.property?.detail;

        const simpleMultiLeadRecommendations = await fetchSimpleMultiLeadRecommendations({
            dispatch,
            sentApplication: null,
            offer,
            property,
            recommendedFilters: {
                max_price
            }
        });

        const hasRecommendations = Array.isArray(simpleMultiLeadRecommendations) && simpleMultiLeadRecommendations.length > 0;
        const sameOfferRecommendedProperties =
            property?.id && hasRecommendations
                ? !(simpleMultiLeadRecommendations as IRecommendedProperty[]).some((recommended) => recommended.offer.id !== offer?.id)
                : false;

        if (onSuccessfulSubmit) {
            onSuccessfulSubmit();
        }

        if (!hasRecommendations) {
            const isRealEstateMultiLead = isAbroad(leadData?.offer?.detail?.region.country);
            const recommendationsType = property ? MultiLeadRecommendationType.PROPERTY : MultiLeadRecommendationType.OFFER;

            hitMultileadRecommendationShown({
                recommendationType: isRealEstateMultiLead ? recommendationsType : MultiLeadRecommendationType.VENDOR,
                recommendationId: null,
                viewType: currentViewType,
                recommendationSource: recommendationSource,
                multileadVersion:
                    recommendationsType === MultiLeadRecommendationType.PROPERTY ? MultileadVersion.RP_LIST_PROPERTY : MultileadVersion.RP_LIST_OFFER
            });
        }

        if (hasRecommendations && sameOfferRecommendedProperties) {
            setUserBudgetMultiLeadStep(simpleMultiLeadRecommendations as IRecommendedProperty[]);
        } else if (hasRecommendations && !sameOfferRecommendedProperties) {
            setDefaultMultiLeadStep(simpleMultiLeadRecommendations);
        } else {
            setUserBudgetNoRecommendationsStep();
        }
    };

    return (
        <div css={modalContentWrapper}>
            <CloseIconButton css={zIndex(10)} onClick={props.onClose} right={2.5} top={2.5} />
            <ApplicationPropertyPriceUserBudget onSubmit={handleUserBudgetSubmit} />
        </div>
    );
};
//

// Styles
const modalContentWrapper = css`
    position: relative;
    ${p(10, 2)};

    ${onDesktop(css`
        ${flex("center", "center")};
        ${p(4)};
        height: 42rem;
        width: 86rem;
    `)};
`;
