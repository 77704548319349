import React from "react";
import {css} from "@emotion/react";

import {calculateRemSize, flex, flexAbsoluteCenter, ml, pb} from "@pg-design/helpers-css";
import {AirportIcon, BeachIcon, PriceIcon} from "@pg-design/icons-module";
import {Text} from "@pg-design/text-module";
import {distanceFormat} from "@pg-mono/string-utils";
import {priceFormat} from "@pg-mono/string-utils";

interface IProps {
    price?: number | null;
    offerDistanceFromTheAirport?: number | null;
    offerDistanceFromTheBeach?: number | null;
    equalColumns?: boolean;
    className?: string;
}

export const MultiLeadCardPictogramsForAbroadOffer = (props: IProps) => {
    const {className, price, offerDistanceFromTheAirport, offerDistanceFromTheBeach} = props;

    return (
        <div css={[pictogramsContainer, props.equalColumns ? pictogramsContainerEqualColumns : ""]} className={className}>
            {price ? (
                <div css={[pictogramContainer, props.equalColumns ? pictogramContainerEqualColumns : ""]}>
                    <div css={iconWrapperStyle}>
                        <PriceIcon size="2.4" />
                    </div>
                    <Text variant="body_copy_2" strong css={ml(0.5)}>
                        {priceFormat(price, {unit: "eur"})}
                    </Text>
                </div>
            ) : null}

            {offerDistanceFromTheAirport ? (
                <div css={[pictogramContainer, props.equalColumns ? pictogramContainerEqualColumns : ""]}>
                    <div css={iconWrapperStyle}>
                        <AirportIcon size="2.4" />
                    </div>
                    <Text variant="body_copy_2" strong css={ml(0.5)}>
                        {distanceFormat(offerDistanceFromTheAirport / 1000, {unit: "km"})}
                    </Text>
                </div>
            ) : null}

            {offerDistanceFromTheBeach ? (
                <div css={[pictogramContainer, props.equalColumns ? pictogramContainerEqualColumns : ""]}>
                    <div css={iconWrapperStyle}>
                        <BeachIcon size="2.4" />
                    </div>
                    <Text variant="body_copy_2" strong css={ml(0.5)}>
                        {distanceFormat(offerDistanceFromTheBeach / 1000, {unit: "km"})}
                    </Text>
                </div>
            ) : null}
        </div>
    );
};

const pictogramsContainer = css`
    ${flex("center", "flex-start")};
    gap: ${calculateRemSize(4)};
    ${pb(1)};
`;

const pictogramsContainerEqualColumns = css`
    gap: ${calculateRemSize(2)};
`;

const pictogramContainer = css`
    display: flex;
    align-items: center;
    justify-content: flex-start;
`;

const iconWrapperStyle = css`
    width: 2.4rem;
    height: 2.4rem;
    ${flexAbsoluteCenter};
`;

const pictogramContainerEqualColumns = css`
    flex: 1 1 auto;
`;
