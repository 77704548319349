import lscache from "lscache";

import {countDistance} from "@pg-mono/geo-utils";
import {sortBy} from "@pg-mono/nodash";
import {convertToCountryLatLngLiteral} from "@pg-mono/open-street-map";

import {SENT_APPLICATIONS} from "../../app/constants/storage_keys";
import {Country} from "../../region/types/Country";

interface ISentOfferApplication {
    id: number;
    coordinates: [number, number];
    country: Country;
    regionId?: number;
}

type CoordinatesType = ISentOfferApplication["coordinates"];
type SentApplicationLocation = Pick<ISentOfferApplication, "coordinates" | "country" | "regionId">;

const getStorageSentApplications = () => {
    const storageSentApplication: Record<string, CoordinatesType | SentApplicationLocation> = lscache.get(SENT_APPLICATIONS) || {};

    /*
     * To this moment we have offers only from Poland. From the moment of introduction abroad offers we need
     * to store country code along with its coordinates - this is important for multilead purposes.
     * In order to add country to existing entries we decided to remove old entries and replace them with new ones.
     */
    const hasLegacyEntries = Object.entries(storageSentApplication).some(([, value]) => Array.isArray(value));

    if (hasLegacyEntries) {
        lscache.set(SENT_APPLICATIONS, {});

        return {};
    }

    return storageSentApplication as Record<string, SentApplicationLocation>;
};

export const addOffersToStorageSentApplication = (offers: ISentOfferApplication[]): void => {
    const sentApplications = getStorageSentApplications();

    const sentOfferApplications = offers.reduce(
        (acc, {id, ...location}) => {
            acc[id] = location;

            return acc;
        },
        {} as Record<number, SentApplicationLocation>
    );

    lscache.set(SENT_APPLICATIONS, {...sentApplications, ...sentOfferApplications});
};

export const getStorageSentApplicationsList = (country: Country = Country.POLAND): ISentOfferApplication[] => {
    const sentApplications = getStorageSentApplications();

    return Object.keys(sentApplications).reduce((acc, key) => {
        const id = parseInt(key);

        if (sentApplications[id].country === country) {
            acc.push({id, coordinates: sentApplications[id].coordinates, country: sentApplications[id].country, regionId: sentApplications[id].regionId});
        }

        return acc;
    }, [] as ISentOfferApplication[]);
};

/**
 * Get offers ids that were saved in local storage and are near target coords.
 * @param limit result ids size
 * @param maxDistance the maximum distance for which the offer will be included in the results
 * @param targetCoords coordinates of target offer
 * @param targetCountry country of target offer
 * @returns {number[]>} matched offers ids
 */
export const getStorageSentApplicationsIdListByDistance = (
    limit: number,
    maxDistance: number,
    targetCoords?: [number, number],
    targetCountry?: Country
): number[] => {
    if (!targetCoords) {
        return [];
    }

    const sentApplicationsList = getStorageSentApplicationsList(targetCountry);

    const {lng, lat} = convertToCountryLatLngLiteral(targetCoords, targetCountry);

    const sentApplicationsListSortedByDistance = sortBy(
        sentApplicationsList.reduce(
            (acc, sentApplication) => {
                const distance = countDistance(convertToCountryLatLngLiteral(sentApplication.coordinates, targetCountry), {lat, lng});

                if (distance <= maxDistance) {
                    acc.push({id: sentApplication.id, distance});
                }

                return acc;
            },
            [] as {id: number; distance: number}[]
        ),
        "distance"
    ).slice(0, limit + 1);

    return sentApplicationsListSortedByDistance.map(({id}) => id);
};

export const getStorageSentApplicationsIdListByRegion = (
    limit: number,
    regionId?: number,
    targetCoords?: [number, number],
    targetCountry?: Country
): number[] => {
    if (!targetCoords) {
        return [];
    }

    const sentApplicationsList = getStorageSentApplicationsList(targetCountry);

    const {lng, lat} = convertToCountryLatLngLiteral(targetCoords, targetCountry);

    const sentApplicationsListSortedByDistance = sortBy(
        sentApplicationsList.reduce(
            (acc, sentApplication) => {
                const distance = countDistance(convertToCountryLatLngLiteral(sentApplication.coordinates, targetCountry), {lat, lng});

                // If regionId is not provided, we want to return all offers from the same country
                // the same if sentApplication.regionId is not provided, we want to return that offer just in case (backward compatibility)
                if (!regionId || !sentApplication.regionId || sentApplication.regionId === regionId) {
                    acc.push({id: sentApplication.id, distance});
                }

                return acc;
            },
            [] as {id: number; distance: number}[]
        ),
        "distance"
    ).slice(0, limit + 1);

    return sentApplicationsListSortedByDistance.map(({id}) => id);
};
