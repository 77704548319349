import React from "react";
import {css, Theme} from "@emotion/react";
import * as Yup from "yup";

import {FormikForm} from "@pg-design/formik-utils";
import {flexAbsoluteCenter, mb, mh, mt, w100} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text-module";

import {validationMessages} from "../../../form/utils/validation_messages";

const initialValues = {
    max_price: ""
};

export type ApplicationUserBudgetFormValuesType = typeof initialValues;

const validationSchema = Yup.object({
    max_price: Yup.number().typeError(validationMessages.number).required(validationMessages.required)
});

interface IProps {
    onSubmit: (values: ApplicationUserBudgetFormValuesType) => void;
}

export const ApplicationPropertyPriceUserBudget = (props: IProps) => {
    return (
        <div css={applicationPropertyPriceUserPrice}>
            <Text align="center" as="div" css={mb(5)} mb="1.6rem" variant="headline_3">
                Do jakiej kwoty chcesz kupić <br /> nieruchomość?
            </Text>
            <FormikForm css={w100} initialValues={initialValues} validationSchema={validationSchema} onSubmit={props.onSubmit} enableReinitialize>
                <div css={formContent}>
                    <FormikForm.Input name="max_price" labelContent="Twój budżet" placeholder="Podaj maksymalną kwotę" />

                    <FormikForm.Button css={submitButton} variant="filled_primary">
                        Zapisz
                    </FormikForm.Button>
                </div>
            </FormikForm>
        </div>
    );
};

const applicationPropertyPriceUserPrice = css`
    ${flexAbsoluteCenter};
    flex-direction: column;
`;

const formContent = (theme: Theme) => css`
    width: 100%;

    @media (min-width: ${theme.breakpoints.md}) {
        ${mh("auto")};
        max-width: 42rem;
        width: 42rem;
    }
`;

const submitButton = css`
    width: 100%;
    ${mt(2)};
`;
