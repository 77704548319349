import {isNumber} from "@pg-mono/nodash";
import {UserComEventNames} from "@pg-mono/user-com";

import {getClientStore} from "../../../client/utils/get_client_store";
import {afterPostApplicationActions, applicationPost, IApplicationOptions, resetApplicationPost} from "../../application/actions/application_form_actions";
import {IApplicationAdditionalQuestionTypes} from "../../application/components/application_form/ApplicationFormContext";
import {IApplicationData} from "../../application/reducers/application_reducer";
import {IApplicationAnalyticsData, sendApplicationHit} from "../../application/tracking/tracking";
import {IApplication} from "../../application/types/IApplication";
import {IApplicationFormValues} from "../../application/types/IApplicationFormValues";
import {IApplicationOffer} from "../../application/types/IApplicationOffer";
import {IApplicationProperty} from "../../application/types/IApplicationProperty";
import {ApplicationSource, ApplicationSourceSection} from "../../application/utils/ApplicationSource";
import {addOffersToStorageSentApplication} from "../../application/utils/manage_storage_sent_applications";
import {addPropertiesToStorageSentApplication} from "../../application/utils/property_application_storage";
import {authUserAfterApplicationSent} from "../../auth/actions/auth_user_after_application_sent";
import {addAppliedOffers} from "../../offer/map/actions/add_applied_offers";
import {facebookPixelPurchase} from "../../tracking/facebook_pixel/facebook_pixel_purchase";
import {gtmEventApplicationRegister} from "../../tracking/google_tag_manager/gtm_event_application_register";
import {gtmInquiryOnApplicationSuccess} from "../../tracking/google_tag_manager/gtm_inquiry";
import {patchUserProfileFromApplication} from "../../user/actions/patch_user_profile_from_application";
import {IUserProfile} from "../../user/ts/interfaces/IUserProfile";
import {ViewType} from "../../view_type/ViewType";
import {hitLeadSuccess} from "../tracking/hit_lead_success";
import {IHitApplicationData} from "../types/IHitApplicationData";
import {IRealEstateIds} from "../types/IRealEstateIds";
import {getParsedLeadFormValues} from "./get_parsed_lead_form_values";
import {updateUserComAfterLead} from "./update_user_com_after_lead";

export type ISendLeadParams = {
    storeHash: string;
    leadData: IHitApplicationData;
    leadFormValues: IApplicationFormValues;
    leadMeta: ILeadMeta;
    isAuthenticated: boolean;
    userProfileData: IUserProfile | null;
    senderState: {
        generatedMessage: string | null;
        questions: IApplicationAdditionalQuestionTypes[];
    };
    analyticsData: IApplicationAnalyticsData;
    analyticsParams?: {
        customSourceSection?: string;
        disableGtmInquiry?: boolean;
    };
    affiliation?: string;
    realEstateIds?: IRealEstateIds;
    postOptions?: IApplicationOptions;
    creditData?: {price: number; contribution: number; period: number};
    onSendSuccess?: (response: IApplication) => void;
};

export interface ILeadMeta {
    source: ApplicationSource;
    sourceSection: ApplicationSourceSection;
    locationHash: string;
    viewType: ViewType | null;
}

export async function sendLead(params: ISendLeadParams) {
    const {
        storeHash,
        leadFormValues,
        leadMeta,
        leadData,
        isAuthenticated,
        senderState,
        analyticsData,
        analyticsParams,
        affiliation,
        postOptions,
        onSendSuccess,
        realEstateIds,
        userProfileData,
        creditData
    } = params;

    const {offer, property} = leadData;

    const leadPayload = getLeadPayloadData({
        leadFormValues,
        leadMeta,
        offer,
        realEstateIds,
        creditData
    });

    const sentLeadRes = await applicationPost(leadPayload, storeHash, postOptions);

    if (sentLeadRes && onSendSuccess) {
        onSendSuccess(sentLeadRes);
    }

    if (sentLeadRes) {
        const {generatedMessage, questions} = senderState;

        await handleSendSuccess({
            response: sentLeadRes,
            offer: offer?.detail,
            property: property?.detail,
            isAuthenticated,
            storeHash,
            userProfileData
        });

        handleLeadTracking({
            name: leadFormValues.name,
            /*
                TODO: Create separate interface for applicationPost, so we won't have to use Partial<IApplication>
                    this will enable to remove "as string" below and will be a better approach in general
                    Application serializer to use for that - python-pg/-/blob/master/rpm/applications/serializers.py
             */
            phone: leadPayload.phone as string,
            email: leadFormValues.email,
            user_id: userProfileData?.uuid || null,
            rooms: leadFormValues.rooms,
            generatedMessage,
            questions,
            analyticsData,
            analyticsParams,
            originalText: leadFormValues.text || "",
            leadResponse: sentLeadRes,
            leadData,
            leadMeta,
            affiliation
        });

        updateUserComAfterLead({
            eventName: UserComEventNames.UserLead,
            sentLead: {
                email: leadFormValues.email,
                phone: leadPayload.phone as string
            },
            userProfile: userProfileData
        });
    }

    return sentLeadRes;
}

interface IGetLeadPayloadParams {
    leadFormValues: IApplicationFormValues;
    leadMeta: ILeadMeta;
    offer: IApplicationData["offer"];
    realEstateIds?: IRealEstateIds;
    creditData?: {price: number; contribution: number; period: number};
}

function getLeadPayloadData(params: IGetLeadPayloadParams) {
    const {leadFormValues, leadMeta, offer, realEstateIds, creditData} = params;
    const {source, sourceSection} = leadMeta;

    const parsedLeadFormValues = getParsedLeadFormValues(leadFormValues);

    const applicationData: Partial<IApplication> = {
        source,
        source_section: sourceSection,
        ...parsedLeadFormValues
    };

    if (creditData) {
        applicationData.financing_services = true;
        applicationData.term_of_the_loan = creditData.period;
        applicationData.calculator_estimated_property_price = creditData.price;
        applicationData.own_contribution = creditData.contribution;
    }

    if (realEstateIds && realEstateIds.salesOfficeId) {
        // variant: 1 disables backend validation of 'meeting_time_preference' field
        applicationData.variant = 1;
        applicationData.sales_office = realEstateIds.salesOfficeId;
    }

    if (realEstateIds && isNumber(realEstateIds.propertyId)) {
        applicationData.property = realEstateIds.propertyId;
    } else if (realEstateIds && isNumber(realEstateIds.offerId)) {
        applicationData.offer = realEstateIds.offerId;
    } else if (realEstateIds && isNumber(realEstateIds.vendorId)) {
        applicationData.vendor = realEstateIds.vendorId;
    }

    if (offer?.detail?.configuration?.fastcall_uid) {
        applicationData.fastcall = true;
    }

    return applicationData;
}

async function handleSendSuccess(props: {
    response: IApplication;
    offer?: Pick<IApplicationOffer, "id" | "geo_point" | "region">;
    property?: Pick<IApplicationProperty, "id">;
    isAuthenticated: boolean;
    storeHash: string;
    userProfileData: IUserProfile | null;
}) {
    const store = getClientStore();

    if (!store) {
        throw new Error("sendLead: store is not available");
    }

    afterPostApplicationActions(props.storeHash, props.response)(store.dispatch);

    if (props.offer) {
        addOffersToStorageSentApplication([
            {
                id: props.offer.id,
                coordinates: props.offer.geo_point.coordinates,
                country: props.offer.region.country,
                regionId: props.offer.region.parent?.id
            }
        ]);
    }

    if (props.property) {
        addPropertiesToStorageSentApplication([props.property.id]);
    }

    // user was created when submitting the application - try to log in
    if (props.response.user_created) {
        await store.dispatch(authUserAfterApplicationSent(props.response, false));
    }
    // try to update logged-in user data
    if (props.isAuthenticated && props.userProfileData && !props.response.user_created) {
        await store.dispatch(patchUserProfileFromApplication(props.userProfileData, props.response));
    }

    if (props.isAuthenticated) {
        store.dispatch(addAppliedOffers([props.response.offer]));
    }

    store.dispatch(resetApplicationPost(props.storeHash));
}

/**
 * Handles tracking after successful lead post/submit
 *
 * @param trackingParams
 * questions - form questions, but from component state, not the form itself
 * generatedMessage - a "rotating" message, but from component state, not the form itself
 * originalText - not modified text, taken directly from form values
 */
function handleLeadTracking(trackingParams: {
    name: string;
    phone: string;
    email: string;
    user_id: string | null;
    rooms: number[];
    questions: IApplicationAdditionalQuestionTypes[];
    generatedMessage: string | null;
    originalText: string;
    analyticsData: IApplicationAnalyticsData;
    analyticsParams?: ISendLeadParams["analyticsParams"];
    leadResponse: IApplication;
    leadData: IHitApplicationData;
    leadMeta: ILeadMeta;
    affiliation?: string;
}) {
    const {
        name,
        phone,
        email,
        rooms,
        generatedMessage,
        questions,
        analyticsData,
        analyticsParams,
        originalText,
        leadResponse,
        leadData,
        leadMeta,
        affiliation
    } = trackingParams;
    const {sourceSection, locationHash, viewType} = leadMeta;
    const phoneWithoutSpace = phone.replace(" ", "");

    if (generatedMessage && generatedMessage !== originalText) {
        sendApplicationHit(questions, name, phoneWithoutSpace, email, analyticsData, generatedMessage, originalText);
    } else {
        sendApplicationHit(questions, name, phoneWithoutSpace, email, analyticsData, generatedMessage ? generatedMessage : "");
    }

    hitLeadSuccess({
        locationHash,
        viewType,
        rooms,
        leadResponse,
        sourceSection,
        leadData
    });

    hitGtmAfterSuccess(leadResponse, leadData, viewType, affiliation, analyticsParams);

    facebookPixelPurchase(leadData);
}

function hitGtmAfterSuccess(
    leadResponse: IApplication,
    leadData: IHitApplicationData,
    viewType: ViewType | null,
    affiliation?: string,
    analyticsParams?: ISendLeadParams["analyticsParams"]
) {
    if (!leadData) {
        throw new Error("hitGtmAfterSuccess: application data should be filled already");
    }

    if (!analyticsParams?.disableGtmInquiry) {
        gtmInquiryOnApplicationSuccess(leadResponse, leadData, viewType, false, affiliation, analyticsParams?.customSourceSection);
    }

    if (leadResponse.user_created) {
        gtmEventApplicationRegister();
    }
}
